import DownloadIcon from "@mui/icons-material/DownloadRounded";
import { Button, Modal, Stack, styled, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Carousel as ResponsiveCarousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { translationKeys } from "../../translations/main-translations";
import CarouselButton from "./CarouselButton";

const CarouselImageContainer = styled("div")(({ theme }) => ({
    display: "flex",
    width: "100%",
    justifyContent: "center",
    height: "100%",
}));

const CustomResponsiveCarousel = styled(ResponsiveCarousel)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100vw",
    height: "100vh",
    position: "relative",
    "& .carousel": {
        // force maximum height, so that position: sticky on .control-dots works properly
        maxHeight: "100%",
    },
    "& .carousel .slider-wrapper": {
        // have some space around to close for large images
        maxWidth: "90%",
    },
    "& .slider .slide": {
        "& img": {
            // use img here instead of custom component to have increased css specificity
            width: "auto",
            height: "auto",
            maxWidth: "100%",
            maxHeight: "100%",
            objectFit: "scale-down",
        },
        // vertically center child item (the image) of a slide
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        // show background of modal
        backgroundColor: "transparent",
        maxHeight: "85vmin",
    },
    "& .carousel .control-dots": {
        // position is relative to .carousel
        position: "sticky", // show thumbs below image, as long as possible
        margin: 0, // replace margin by padding, to work around margin-collapsing
        padding: theme.spacing(2, 0),
    },
}));

const ImageCaption = styled(Typography)(({ theme }) => ({
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightMedium,
}));

const ButtonWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "flex-end",
    // Use fixed positioning to remove button from document flow so that its sibling can use "height: 100vh"
    position: "fixed",
    top: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: theme.zIndex.modal,
}));

const ImageWrapper = styled("div")({
    // Signal child image to be not larger than necessary
    overflow: "hidden",
});

const DownloadButton = styled(Button)(({ theme }) => ({
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
    "&:hover": {
        backgroundColor: theme.palette.grey[400],
    },
}));

export type CarouselItem = {
    displayName?: string;
    imgLink: string;
};

interface ICarouselProps {
    onClose: () => void;
    onChange: (index: number) => void;
    currentIndex: number | null;
    items: CarouselItem[];
}

const preventPropagation = (event: React.SyntheticEvent) => {
    event.stopPropagation();
};

const Carousel = ({ items, onClose, currentIndex, onChange }: ICarouselProps) => {
    const renderPrevButton = (onClick: () => void, hasItems: boolean, label: string) => (
        <CarouselButton role="previous" show={hasItems} label={label} onClick={onClick} />
    );

    const renderNextButton = (onClick: () => void, hasItems: boolean, label: string) => (
        <CarouselButton role="next" show={hasItems} label={label} onClick={onClick} />
    );

    const poorMansBackdropHandler = ({ target }: React.MouseEvent<HTMLElement>) => {
        if (target instanceof HTMLElement && target.classList.contains("dot")) {
            // dont close when clicking on navigation dots
            return;
        }
        onClose();
    };

    const { t: translate } = useTranslation();

    return (
        <Modal open={currentIndex != null} onClose={onClose}>
            <div onClick={poorMansBackdropHandler}>
                <ButtonWrapper onClick={preventPropagation}>
                    <DownloadButton
                        size="medium"
                        variant="contained"
                        startIcon={<DownloadIcon />}
                        href={currentIndex !== null ? items[currentIndex].imgLink : undefined}
                    >
                        {translate(translationKeys.VDLANG_DOWNLOAD)}
                    </DownloadButton>
                </ButtonWrapper>
                <CustomResponsiveCarousel
                    showThumbs={false}
                    showStatus={false}
                    showIndicators={items.length > 1}
                    selectedItem={currentIndex ?? 0}
                    onChange={onChange}
                    renderArrowPrev={renderPrevButton}
                    renderArrowNext={renderNextButton}
                    useKeyboardArrows
                    autoFocus
                >
                    {items.map((src) => (
                        <CarouselImageContainer key={src.imgLink}>
                            <Stack spacing={2} sx={{ height: "100%", width: "100%" }} justifyContent="center">
                                <ImageWrapper>
                                    <img src={src.imgLink} alt={src.displayName} onClick={preventPropagation} />
                                </ImageWrapper>
                                <ImageCaption variant="body2">{src.displayName}</ImageCaption>
                            </Stack>
                        </CarouselImageContainer>
                    ))}
                </CustomResponsiveCarousel>
            </div>
        </Modal>
    );
};

export default Carousel;
