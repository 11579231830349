import { Alert, Box, Collapse, Divider, FormControlLabel, Stack, styled, Switch, TextField, Typography } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { CalendarPicker, CalendarPickerProps } from "@mui/x-date-pickers/CalendarPicker";
import { TFunction } from "i18next";
import moment, { Moment } from "moment";
import momentTimezone from "moment-timezone";
import { ChangeEvent, useState } from "react";
import useTimezone from "../../../hooks/useTimezone";
import { translationKeys } from "../../../translations/main-translations";
import { isDateWithinTimestampRange } from "api-shared";
import { DateTimeValidationError } from "@mui/x-date-pickers/internals/hooks/validation/useDateTimeValidation";

const Root = styled(Stack)(({ theme }) => ({
    paddingBottom: theme.spacing(2),
    width: "328px",
}));

const DATE_FORMAT_TEMPLATE = "YYYY-MM-DD";

function getErrorLabel(date: moment.Moment | null, minDate?: Moment | null, maxDate?: Moment | null) {
    if (date != null && minDate != null && date.isBefore(minDate)) {
        return translationKeys.VDLANG_DATEPICKER_TOO_EARLY;
    }
    if (date != null && maxDate != null && date.isAfter(maxDate)) {
        return translationKeys.VDLANG_DATEPICKER_TOO_LATE;
    }

    return null;
}

/**
 * MaterialUi always returns Moments in the timezone of the browser.
 * In case this differs from the timezone in userprofile we need to create a moment in the correct timezone and transform it into utc.
 *
 * @param {Moment} date
 * @param {string} userTimezone
 * @returns {Moment}
 */
function createUTCMoment(date: Moment, userTimezone: string): Moment {
    return momentTimezone.tz(`${date.format(DATE_FORMAT_TEMPLATE)} ${date.format("HH:mm")}`, userTimezone).utc();
}

interface IDatePickerReminderPopoverProps extends Omit<CalendarPickerProps<Moment>, "translate"> {
    translate: TFunction;
    remindAt: Date | null;
    onReminderChange: (newValue: Moment | null) => void;
    onReminderToggled?: () => void;
    infoMessage?: string;
}

const DatePickerReminderPopover = ({
    date,
    onChange,
    translate,
    remindAt,
    onReminderChange,
    onReminderToggled,
    infoMessage,
    ...calendarProps
}: IDatePickerReminderPopoverProps) => {
    const { timezone } = useTimezone();

    const [reminderActive, setReminderActive] = useState<boolean>(remindAt != null);
    const [reminderDateTime, setReminderDateTime] = useState<Moment | null>(
        remindAt != null ? momentTimezone.utc(remindAt).tz(timezone) : null,
    );

    // Keep track of errors provided by the onError callback of the reminder date/time components
    const [reminderDateTimeError, setReminderDateTimeError] = useState<DateTimeValidationError>(null);

    const setReminderFromDuedate = () => {
        if (date == null) {
            return;
        }
        const newReminder = moment(date.format(DATE_FORMAT_TEMPLATE)).subtract(3, "days").add(9, "hours");

        if (newReminder.isAfter(moment()) && isDateWithinTimestampRange(newReminder)) {
            onReminderChange(createUTCMoment(newReminder, timezone));
            setReminderDateTime(newReminder);
        }
    };

    const handleReminderActiveChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;
        if (checked === reminderActive) {
            return;
        }
        setReminderActive(checked);
        if (checked) {
            setReminderFromDuedate();
            return;
        }
        setReminderDateTime(null);
        onReminderChange(null);
    };

    const updateReminderIfComplete = (newDateTime: Moment | null) => {
        if (newDateTime?.isValid() && isDateWithinTimestampRange(newDateTime)) {
            onReminderChange(createUTCMoment(newDateTime, timezone));
        }
    };

    const handleReminderDateTimeChange = (newDateTime: Moment | null) => {
        setReminderDateTime(newDateTime);
        updateReminderIfComplete(newDateTime);
    };

    const error = getErrorLabel(date, calendarProps.minDate, calendarProps.maxDate);

    let reminderDateTimeErrorMessage: string | undefined = undefined;
    if (reminderDateTime?.isValid() && reminderDateTimeError === "disablePast") {
        reminderDateTimeErrorMessage = translate(translationKeys.VDLANG_REMINDER_IN_PAST);
    } else if (reminderDateTime?.isValid() && !isDateWithinTimestampRange(reminderDateTime)) {
        reminderDateTimeErrorMessage = translate(translationKeys.VDLANG_REMINDER_TOO_FAR_IN_FUTURE);
    } else if (reminderDateTimeError != null) {
        reminderDateTimeErrorMessage = translate("Invalid date");
    }

    return (
        <Root>
            {error != null && (
                <Alert severity="error" variant="inline">
                    {translate(error)}
                </Alert>
            )}
            {infoMessage && (
                <Alert severity="info" variant="inline">
                    {infoMessage}
                </Alert>
            )}
            <CalendarPicker<Moment> date={date} onChange={onChange} {...calendarProps} />
            <Divider />
            <FormControlLabel
                sx={{ pt: 1, px: 3 }}
                control={<Switch checked={reminderActive} onChange={handleReminderActiveChange} />}
                label={translate(translationKeys.VDLANG_EMAIL_REMINDER)}
            />
            <Collapse in={reminderActive} onEntered={onReminderToggled} onExited={onReminderToggled}>
                <Box sx={{ pt: 1, px: 3 }}>
                    <Typography color="textSecondary" sx={{ mb: 1 }}>
                        {translate(translationKeys.VDLANG_REMINDER_HINT)}
                    </Typography>
                    <DateTimePicker<Moment>
                        onChange={handleReminderDateTimeChange}
                        value={reminderDateTime}
                        ampm={false}
                        ampmInClock={false}
                        disablePast
                        maxDateTime={momentTimezone.utc("2038-01-18")} // Disable picker selection beyond timestamp range
                        PopperProps={{
                            disablePortal: true, // Because this is a popover inside a popover, we need to disable the portal to prevent rendering issues
                        }}
                        renderInput={(props) => (
                            <TextField
                                {...props}
                                error={
                                    Boolean(props.error) || (reminderDateTime?.isValid() && !isDateWithinTimestampRange(reminderDateTime))
                                }
                                fullWidth
                                helperText={reminderDateTimeErrorMessage}
                                size="small"
                            />
                        )}
                        onError={setReminderDateTimeError}
                    />
                </Box>
            </Collapse>
        </Root>
    );
};
export default DatePickerReminderPopover;
