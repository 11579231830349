import AccountTreeRounded from "@mui/icons-material/AccountTreeRounded";
import { styled, Tooltip } from "@mui/material";
import { nonNullable } from "api-shared";
import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    CheckStatus,
    findNode,
    getIds,
    memoizedGetPathsWithSelection,
    NamedTreeNode,
    renderPath,
    traverseTree,
    type PathWithSelection,
    type TreeNode,
} from "../../../lib/tree";
import { translationKeys } from "../../../translations/main-translations";
import ActionItemDialog from "../../dialogues/ActionItemDialog";
import { baseDialogClasses } from "../../dialogues/BaseDialog";
import { SelectValueContainerPointer } from "../select/components/SelectValueContainer";
import Select from "../select/Select";
import SelectButton from "../select/SelectButton";
import type { ISelectProps, Option } from "../select/types";
import TreeInputDialogContent from "./TreeInputDialogContent";
import TreeInputDropdownIndicator from "./TreeInputDrowdownIndicator";

const StyledDialog = styled(ActionItemDialog)(({ theme }) => ({
    [`& .${baseDialogClasses.content}`]: {
        padding: 0,
        // Use grid display so that child can easily grow to full height and can manage its own overflow behavior
        display: "grid",
    },
}));

const TreeIcon = styled(AccountTreeRounded)(({ theme }) => ({
    fontSize: "1rem",
    marginLeft: theme.spacing(1),
}));

function TreeIconWithTooltip() {
    const { t: translate } = useTranslation();
    return (
        <Tooltip title={translate(translationKeys.VDLANG_SUBTREE_SELECTED_ICON_TOOLTIP)}>
            <TreeIcon color="action" />
        </Tooltip>
    );
}

function applyRecursively(newValues: number[], oldValues: number[], allNodes: TreeNode[]) {
    let modifiedValues = newValues;

    const added = modifiedValues.filter((id) => !oldValues.includes(id));
    modifiedValues = added.reduce((acc, addedId) => {
        // Find children and select them
        const addedNode = allNodes?.find((node) => node.id === addedId);
        return selectRecursively(addedNode, acc);
    }, modifiedValues);

    const removed = oldValues.filter((id) => !modifiedValues.includes(id));
    // Deselect whole subtree on deselection
    modifiedValues = removed.reduce((acc, removedId) => {
        const removedNode = allNodes?.find((node) => node.id === removedId);
        return deselectRecursively(removedNode, acc);
    }, modifiedValues);

    return modifiedValues;
}

function deselectRecursively(removedNode: TreeNode | undefined, values: number[]) {
    const childIds = traverseTree(removedNode, getIds) ?? [];
    return [...new Set(values.filter((id) => !childIds.includes(id)))];
}

function selectRecursively(addedNode: TreeNode | undefined, values: number[]) {
    const childIds = traverseTree(addedNode, getIds) ?? [];
    return [...new Set([...values, ...childIds])];
}

export interface TreeInputProps extends Omit<ISelectProps<Option<number>, boolean>, "value" | "options"> {
    value: number[] | number | null;
    updateValue: (newValue: number[] | number | null) => void;
    label?: React.ReactNode;

    dialogTitle?: React.ReactNode;
    dense?: boolean;
    isMulti: boolean | undefined;
    data: NamedTreeNode[];
    disabled?: boolean;
    narrowSelection: boolean;
}

const TreeInput = (props: TreeInputProps) => {
    const { value: oldValue, updateValue, data, disabled, dense, dialogTitle, label, narrowSelection, ...selectProps } = props;

    // select props additionally used within this component, but also passed further down
    const { isMulti, size } = selectProps;

    const oldValues = oldValue != null ? [oldValue].flat() : [];

    const { t: translate } = useTranslation();

    const [open, setOpen] = useState(false);
    const [newValues, setNewValues] = useState<number[] | undefined>(undefined);

    const selectedValues = newValues ?? oldValues;

    const newPaths = memoizedGetPathsWithSelection(data, selectedValues);
    const oldPaths = memoizedGetPathsWithSelection(data, oldValues);

    const allNodes = newPaths?.map((path) => path.path.at(-1)).filter(nonNullable) ?? [];

    const newOptions =
        newPaths
            ?.filter((path) => path.path.length > 0)
            .map<Option<number>>(({ status, path }: PathWithSelection) => {
                const { id, children } = path[path.length - 1]; // deepest node of the path
                return {
                    value: id,
                    label: renderPath(path as NamedTreeNode[]),
                    icon: children != null && children.length > 0 && status === CheckStatus.YES ? <TreeIconWithTooltip /> : undefined,
                };
            }) ?? [];

    const oldOptions =
        oldPaths
            ?.filter((path) => path.path.length > 0)
            .map<Option<number>>(({ status, path }: PathWithSelection) => {
                const { id, children } = path[path.length - 1]; // deepest node of the path
                return {
                    value: id,
                    label: renderPath(path as NamedTreeNode[]),
                    icon: children != null && children.length > 0 && status === CheckStatus.YES ? <TreeIconWithTooltip /> : undefined,
                };
            }) ?? [];

    const oldSelectedOptions = oldOptions.filter((option) => oldValues.includes(option.value));
    const newSelectedOptions = newOptions.filter((option) => selectedValues.includes(option.value));

    const handleNewSelection = (newNewValues: number[]): void => {
        const wrappedValues = isMulti ? applyRecursively(newNewValues, selectedValues, allNodes) : newNewValues;
        setNewValues(wrappedValues);
    };

    const handleOldSelection = (newOldValues: number[]): void => {
        const wrappedValues = isMulti ? applyRecursively(newOldValues, oldValues, allNodes) : newOldValues;

        updateValue(isMulti ? wrappedValues : (wrappedValues[0] ?? null));
    };

    const handleOldSelectSelection = (options: readonly Option<number>[] | Option<number> | null) => {
        const newOptionsList = [options ?? []].flat();

        const newValues = isMulti
            ? newOptionsList.flatMap((newOption) => {
                  const node = allNodes.find((node) => node.id === newOption.value);
                  return traverseTree(node, getIds) ?? [];
              })
            : newOptionsList.map((option) => option.value);

        handleOldSelection(newValues);
    };

    const hasIllegalSelectedNode =
        narrowSelection && oldSelectedOptions.some((option) => traverseTree(data, findNode(option.value))?.selectable === false);

    const hasChanges = selectedValues.length !== oldValues.length || selectedValues.some((v) => !oldValues.includes(v));

    return (
        <Fragment>
            <StyledDialog
                open={open}
                onClose={() => setOpen(false)}
                translate={translate}
                primary={translationKeys.VDLANG_SAVE}
                onPrimary={() => updateValue(isMulti ? selectedValues : (selectedValues[0] ?? null))}
                primaryDisabled={!hasChanges}
                title={dialogTitle ?? label}
                // reset dialog AFTER dialog has been closed. With onClose, the reset state would be shortly visible
                onTransitionExited={() => setNewValues(undefined)}
            >
                <TreeInputDialogContent
                    value={selectedValues}
                    updateValue={handleNewSelection}
                    data={data}
                    disabled={disabled}
                    isMulti={isMulti}
                    selectedOptions={newSelectedOptions}
                    narrowSelection={narrowSelection}
                />
            </StyledDialog>
            {dense ? (
                <SelectButton
                    count={oldValues.length}
                    label={label}
                    onClick={() => setOpen(true)}
                    dropdownIcon={<AccountTreeRounded color="action" />}
                    size={size}
                    className={props.className}
                    disabled={disabled && oldValues.length === 0}
                    fullWidth={props.fullWidth}
                />
            ) : (
                <Select
                    error={hasIllegalSelectedNode}
                    value={oldSelectedOptions}
                    options={oldOptions}
                    onChange={handleOldSelectSelection}
                    ellipsisLeft
                    isDisabled={Boolean(disabled)}
                    menuIsOpen={false}
                    onMenuOpen={() => setOpen(true)}
                    components={{
                        DropdownIndicator: TreeInputDropdownIndicator,
                        ValueContainer: SelectValueContainerPointer,
                        ...(selectProps.components ?? {}),
                    }}
                    label={label}
                    {...selectProps}
                />
            )}
        </Fragment>
    );
};

export default TreeInput;
