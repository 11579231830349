enum Keys {
    VDLANG_FEEDBACK_PASSWORD_RESET_SENT = "VDLANG_FEEDBACK_PASSWORD_RESET_SENT",
    VDLANG_FEEDBACK_RESET_2FA = "VDLANG_FEEDBACK_RESET_2FA",
    VDLANG_FEEDBACK_USER_INVITED = "VDLANG_FEEDBACK_USER_INVITED",
    VDLANG_FEEDBACK_REQUEST_SENT = "VDLANG_FEEDBACK_REQUEST_SENT",
    VDLANG_FEEDBACK_MEASURES_UPDATED = "VDLANG_FEEDBACK_MEASURES_UPDATED",
    VDLANG_FEEDBACK_OPPS_UPDATED = "VDLANG_FEEDBACK_OPPS_UPDATED",
    VDLANG_FEEDBACK_COMPANY_UPDATED = "VDLANG_FEEDBACK_COMPANY_UPDATED",
    VDLANG_FEEDBACK_COMPANY_REMOVED = "VDLANG_FEEDBACK_COMPANY_REMOVED",
    VDLANG_FEEDBACK_MEASURE_UPDATED = "VDLANG_FEEDBACK_MEASURE_UPDATED",
    VDLANG_FEEDBACK_LAST_GATE_TASK_REOPENED = "VDLANG_FEEDBACK_LAST_GATE_TASK_REOPENED",
    VDLANG_FEEDBACK_USER_DELETED = "VDLANG_FEEDBACK_USER_DELETED",
    VDLANG_FEEDBACK_USER_UPDATED = "VDLANG_FEEDBACK_USER_UPDATED",
    VDLANG_FEEDBACK_USER_DEACTIVATED_OR_DELETED = "VDLANG_FEEDBACK_USER_DEACTIVATED_OR_DELETED",
    VDLANG_FEEDBACK_CLIENT_UPDATED = "VDLANG_FEEDBACK_CLIENT_UPDATED",
    VDLANG_FEEDBACK_CLIENT_LOGO_UPDATED = "VDLANG_FEEDBACK_CLIENT_LOGO_UPDATED",
    VDLANG_FEEDBACK_IDEA_NOT_FOUND = "VDLANG_FEEDBACK_IDEA_NOT_FOUND",
    VDLANG_FEEDBACK_CLIENT_LOGO_DELETED = "VDLANG_FEEDBACK_CLIENT_LOGO_DELETED",
    VDLANG_FEEDBACK_COMPANY_CREATED = "VDLANG_FEEDBACK_COMPANY_CREATED",
    VDLANG_FEEDBACK_DECISION_REQUESTED = "VDLANG_FEEDBACK_DECISION_REQUESTED",
    VDLANG_FEEDBACK_IDEA_CREATED = "VDLANG_FEEDBACK_IDEA_CREATED",
    VDLANG_FEEDBACK_IDEA_DISCARDED = "VDLANG_FEEDBACK_IDEA_DISCARDED",
    VDLANG_FEEDBACK_IDEA_TO_MEASURE = "VDLANG_FEEDBACK_IDEA_TO_MEASURE",
    VDLANG_FEEDBACK_OWN_PASSWORD_RESET_SENT = "VDLANG_FEEDBACK_OWN_PASSWORD_RESET_SENT",
    VDLANG_FEEDBACK_PASSWORD_CHANGED = "VDLANG_FEEDBACK_PASSWORD_CHANGED",
    VDLANG_FEEDBACK_CLIENT_CREATED = "VDLANG_FEEDBACK_CLIENT_CREATED",
    VDLANG_FEEDBACK_CLIENT_SAVED = "VDLANG_FEEDBACK_CLIENT_SAVED",
    VDLANG_FEEDBACK_TRANSLATION_CREATED = "VDLANG_FEEDBACK_TRANSLATION_CREATED",
    VDLANG_FEEDBACK_TRANSLATION_SAVED = "VDLANG_FEEDBACK_TRANSLATION_SAVED",
    VDLANG_FEEDBACK_TRANSLATION_DELETED = "VDLANG_FEEDBACK_TRANSLATION_DELETED",
    VDLANG_FEEDBACK_COSTLEVER_CREATED = "VDLANG_FEEDBACK_COSTLEVER_CREATED",
    VDLANG_FEEDBACK_COSTLEVER_UPDATED = "VDLANG_FEEDBACK_COSTLEVER_UPDATED",
    VDLANG_FEEDBACK_COSTLEVER_DELETED = "VDLANG_FEEDBACK_COSTLEVER_DELETED",
    VDLANG_FEEDBACK_AVATAR_DELETED = "VDLANG_FEEDBACK_AVATAR_DELETED",
    VDLANG_FEEDBACK_NOTIFICATIONS_UPDATED = "VDLANG_FEEDBACK_NOTIFICATIONS_UPDATED",
    VDLANG_FEEDBACK_PROFILE_UPDATED = "VDLANG_FEEDBACK_PROFILE_UPDATED",
    VDLANG_FEEDBACK_DOMAIN_CREATED = "VDLANG_FEEDBACK_DOMAIN_CREATED",
    VDLANG_FEEDBACK_DOMAIN_UPDATED = "VDLANG_FEEDBACK_DOMAIN_UPDATED",
    VDLANG_FEEDBACK_DOMAIN_DELETED = "VDLANG_FEEDBACK_DOMAIN_DELETED",
    VDLANG_FEEDBACK_CURRENCY_CREATED = "VDLANG_FEEDBACK_CURRENCY_CREATED",
    VDLANG_FEEDBACK_CURRENCY_UPDATED = "VDLANG_FEEDBACK_CURRENCY_UPDATED",
    VDLANG_FEEDBACK_CURRENCY_DELETED = "VDLANG_FEEDBACK_CURRENCY_DELETED",
    VDLANG_FEEDBACK_IDENTITY_PROVIDER_CREATED = "VDLANG_FEEDBACK_IDENTITY_PROVIDER_CREATED",
    VDLANG_FEEDBACK_IDENTITY_PROVIDER_UPDATED = "VDLANG_FEEDBACK_IDENTITY_PROVIDER_UPDATED",
    VDLANG_FEEDBACK_IDENTITY_PROVIDER_DELETED = "VDLANG_FEEDBACK_IDENTITY_PROVIDER_DELETED",
    VDLANG_FEEDBACK_TREE_NODES_UPLOAD_SUCCESS = "VDLANG_FEEDBACK_TREE_NODES_UPLOAD_SUCCESS",
    VDLANG_FEEDBACK_TREE_NODES_UPLOAD_ERROR = "VDLANG_FEEDBACK_TREE_NODES_UPLOAD_ERROR",
    VDLANG_FEEDBACK_TREE_NODE_CREATED_SUCCESS = "VDLANG_FEEDBACK_TREE_NODE_CREATED_SUCCESS",
    VDLANG_FEEDBACK_TREE_NODE_DELETED_SUCCESS = "VDLANG_FEEDBACK_TREE_NODE_DELETED_SUCCESS",
    VDLANG_FEEDBACK_TREE_NODE_UPDATED_SUCCESS = "VDLANG_FEEDBACK_TREE_NODE_UPDATED_SUCCESS",
    VDLANG_FEEDBACK_TREE_NODE_ORDER_UPDATED_SUCCESS = "VDLANG_FEEDBACK_TREE_NODE_ORDER_UPDATED_SUCCESS",
    VDLANG_FEEDBACK_CUSTOMFIELD_CREATED = "VDLANG_FEEDBACK_CUSTOMFIELD_CREATED",
    VDLANG_FEEDBACK_CUSTOMFIELD_UPDATED = "VDLANG_FEEDBACK_CUSTOMFIELD_UPDATED",
    VDLANG_FEEDBACK_CUSTOMFIELD_DELETED = "VDLANG_FEEDBACK_CUSTOMFIELD_DELETED",
    VDLANG_FEEDBACK_CUSTOMVALUE_CREATED = "VDLANG_FEEDBACK_CUSTOMVALUE_CREATED",
    VDLANG_FEEDBACK_CUSTOMVALUE_UPDATED = "VDLANG_FEEDBACK_CUSTOMVALUE_UPDATED",
    VDLANG_FEEDBACK_CUSTOMVALUE_DELETED = "VDLANG_FEEDBACK_CUSTOMVALUE_DELETED",

    VDLANG_FEEDBACK_DEPARTMENT_CREATED = "VDLANG_FEEDBACK_DEPARTMENT_CREATED",
    VDLANG_FEEDBACK_DEPARTMENT_UPDATED = "VDLANG_FEEDBACK_DEPARTMENT_UPDATED",
    VDLANG_FEEDBACK_DEPARTMENT_DELETED = "VDLANG_FEEDBACK_DEPARTMENT_DELETED",
    VDLANG_FEEDBACK_WIDGET_CREATED = "VDLANG_FEEDBACK_WIDGET_CREATED",
    VDLANG_FEEDBACK_WIDGET_DUPLICATED = "VDLANG_FEEDBACK_WIDGET_DUPLICATED",
    VDLANG_FEEDBACK_PARTNER_CREATED = "VDLANG_FEEDBACK_PARTNER_CREATED",
    VDLANG_FEEDBACK_PARTNER_UPDATED = "VDLANG_FEEDBACK_PARTNER_UPDATED",
    VDLANG_FEEDBACK_SEARCH_TOO_LONG = "VDLANG_FEEDBACK_SEARCH_TOO_LONG",

    VDLANG_FEEDBACK_GROUP_CREATED = "VDLANG_FEEDBACK_GROUP_CREATED",
    VDLANG_FEEDBACK_GROUP_UPDATED = "VDLANG_FEEDBACK_GROUP_UPDATED",
    VDLANG_FEEDBACK_GROUP_DELETED = "VDLANG_FEEDBACK_GROUP_DELETED",

    VDLANG_FEEDBACK_PERMISSION_CREATED = "VDLANG_FEEDBACK_PERMISSION_CREATED",
    VDLANG_FEEDBACK_USER_PERMISSION_DELETED = "VDLANG_FEEDBACK_USER_PERMISSION_DELETED",
    VDLANG_FEEDBACK_GROUP_PERMISSION_DELETED = "VDLANG_FEEDBACK_GROUP_PERMISSION_DELETED",
    VDLANG_FEEDBACK_DASHBOARD_PERMISSIONS_NEEDED = "VDLANG_FEEDBACK_DASHBOARD_PERMISSIONS_NEEDED",

    VDLANG_ATTRIBUTE_MIGRATE_TO_TREE = "VDLANG_ATTRIBUTE_MIGRATE_TO_TREE",
    VDLANG_ATTRIBUTE_MIGRATE_TO_TREE_FAILED = "VDLANG_ATTRIBUTE_MIGRATE_TO_TREE_FAILED",
}
Object.freeze(Keys);
export { Keys as FeedbackTranslationKeys };

export const feedbackTranslations = {
    [Keys.VDLANG_FEEDBACK_PASSWORD_RESET_SENT]: [
        "Email for password reset has been sent to the user",
        "Eine E-Mail zur Passwort-Zurücksetzung wurde an den Benutzer gesendet",
    ],
    [Keys.VDLANG_FEEDBACK_RESET_2FA]: ["Two-factor authentication has been reset", "Zwei-Faktor-Authentifizierung wurde zurückgesetzt"],
    [Keys.VDLANG_FEEDBACK_USER_INVITED]: ["User invited successfully", "Benutzer erfolgreich eingeladen"],
    [Keys.VDLANG_FEEDBACK_REQUEST_SENT]: [
        "Your request has been sent. We will contact you soon",
        "Ihre Anfrage wurde abgeschickt. Wir melden uns in Kürze",
    ],
    [Keys.VDLANG_FEEDBACK_MEASURES_UPDATED]: ["Measures updated successfully.", "Maßnahmen erfolgreich aktualisiert"],
    [Keys.VDLANG_FEEDBACK_OPPS_UPDATED]: ["Opportunities updated successfully.", "Opportunitäten erfolgreich aktualisiert"],
    [Keys.VDLANG_FEEDBACK_COMPANY_UPDATED]: ["Company updated successfully", "Gesellschaft erfolgreich aktualisiert"],
    [Keys.VDLANG_FEEDBACK_COMPANY_REMOVED]: ["Company removed successfully", "Gesellschaft erfolgreich gelöscht"],
    [Keys.VDLANG_FEEDBACK_MEASURE_UPDATED]: ["Measure updated successfully.", " Maßnahme erfolgreich aktualisiert"],
    [Keys.VDLANG_FEEDBACK_LAST_GATE_TASK_REOPENED]: ["Last level reopened successfully.", "Maßnahme erfolgreich zurückgestuft"],
    [Keys.VDLANG_FEEDBACK_USER_DELETED]: ["User deleted", "Benutzer gelöscht"],
    [Keys.VDLANG_FEEDBACK_USER_UPDATED]: ["User updated successfully", "Benutzer erfolgreich aktualisiert"],
    [Keys.VDLANG_FEEDBACK_USER_DEACTIVATED_OR_DELETED]: ["Invite link is not valid anymore", "Einladungslink ist nicht mehr valide"],
    [Keys.VDLANG_FEEDBACK_CLIENT_UPDATED]: ["Organization updated successfully", "Unternehmen erfolgreich aktualisiert"],
    [Keys.VDLANG_FEEDBACK_CLIENT_LOGO_UPDATED]: ["Logo updated successfully", "Unternehmenslogo erfolgreich aktualisiert"],
    [Keys.VDLANG_FEEDBACK_IDEA_NOT_FOUND]: ["Opportunity does not exist", "Opportunität existiert nicht"],
    [Keys.VDLANG_FEEDBACK_CLIENT_LOGO_DELETED]: ["Logo deleted", "Unternehmenslogo gelöscht"],
    [Keys.VDLANG_FEEDBACK_COMPANY_CREATED]: ["Company created successfully", "Gesellschaft erfolgreich erstellt"],
    [Keys.VDLANG_FEEDBACK_DECISION_REQUESTED]: ["Decision requested successfully", "Entscheidung angefragt"],
    [Keys.VDLANG_FEEDBACK_IDEA_CREATED]: ["Opportunity successfully created", "Opportunität wurde erfolgreich angelegt"],
    [Keys.VDLANG_FEEDBACK_IDEA_DISCARDED]: ["Opportunity discarded", "Opportunität verworfen"],
    [Keys.VDLANG_FEEDBACK_IDEA_TO_MEASURE]: [
        "Opportunity successfully converted into a measure",
        "Opportunität erfolgreich in eine Maßnahme konvertiert",
    ],
    [Keys.VDLANG_FEEDBACK_OWN_PASSWORD_RESET_SENT]: [
        "Please continue with the instructions sent to you by mail.",
        "Bitte die Anweisungen befolgen, die an die Email-Adresse verschickt wurden.",
    ],
    [Keys.VDLANG_FEEDBACK_PASSWORD_CHANGED]: ["Password changed successfully", "Passwort erfolgreich geändert"],
    [Keys.VDLANG_FEEDBACK_CLIENT_CREATED]: ["Client created", "Client wurde angelegt"],
    [Keys.VDLANG_FEEDBACK_CLIENT_SAVED]: ["Client saved", "Client wurde gespeichert"],
    [Keys.VDLANG_FEEDBACK_TRANSLATION_CREATED]: ["Translation created", "Übersetzung hinzugefügt"],
    [Keys.VDLANG_FEEDBACK_TRANSLATION_SAVED]: ["Translation saved", "Übersetzung gespeichert"],
    [Keys.VDLANG_FEEDBACK_TRANSLATION_DELETED]: ["Translation deleted", "Übersetzung gelöscht"],
    [Keys.VDLANG_FEEDBACK_COSTLEVER_CREATED]: ["Method created", "Methode hinzugefügt"],
    [Keys.VDLANG_FEEDBACK_COSTLEVER_UPDATED]: ["Method updated", "Methode gespeicher"],
    [Keys.VDLANG_FEEDBACK_COSTLEVER_DELETED]: ["Method deleted", "Methode gelöscht"],
    [Keys.VDLANG_FEEDBACK_AVATAR_DELETED]: ["User avatar deleted", "Profilbild wurde gelöscht"],
    [Keys.VDLANG_FEEDBACK_NOTIFICATIONS_UPDATED]: [
        "Notifications settings updated successfully",
        "Benachrichtigungseinstellungen gespeichert",
    ],
    [Keys.VDLANG_FEEDBACK_PROFILE_UPDATED]: ["Profile updated successfully", "Profil gespeichert"],
    [Keys.VDLANG_FEEDBACK_DOMAIN_CREATED]: ["Domain created successfully", "Domain hinzugefügt"],
    [Keys.VDLANG_FEEDBACK_DOMAIN_UPDATED]: ["Domain updated successfully", "Domain gespeichert"],
    [Keys.VDLANG_FEEDBACK_DOMAIN_DELETED]: ["Domain deleted successfully", "Domain gelöscht"],
    [Keys.VDLANG_FEEDBACK_CURRENCY_CREATED]: ["Currency created successfully", "Währung hinzugefügt"],
    [Keys.VDLANG_FEEDBACK_CURRENCY_UPDATED]: ["Currency updated successfully", "Währung gespeichert"],
    [Keys.VDLANG_FEEDBACK_CURRENCY_DELETED]: ["Currency deleted successfully", "Währung gelöscht"],
    [Keys.VDLANG_FEEDBACK_IDENTITY_PROVIDER_CREATED]: ["SignOn created successfully", "Anmeldedienst hinzugefügt"],
    [Keys.VDLANG_FEEDBACK_IDENTITY_PROVIDER_UPDATED]: ["SignOn updated successfully", "Anmeldedienst gespeichert"],
    [Keys.VDLANG_FEEDBACK_IDENTITY_PROVIDER_DELETED]: ["SignOn deleted successfully", "Anmeldedienst gelöscht"],
    [Keys.VDLANG_FEEDBACK_TREE_NODES_UPLOAD_SUCCESS]: ["Tree nodes uploaded successfully", "Baumknoten erfolgreich hochgeladen"],
    [Keys.VDLANG_FEEDBACK_TREE_NODES_UPLOAD_ERROR]: [
        "Uploaded Template caused some errors, either a invalid file or the node entries arent filled",
        "Die hochgeladene Vorlage hat Fehler verursacht, entweder eine invalide Datei oder einige Knoten sind nicht ausgefüllt",
    ],
    [Keys.VDLANG_FEEDBACK_TREE_NODE_CREATED_SUCCESS]: ["Tree node created successfully", "Baumknoten erfolgreich angelegt"],
    [Keys.VDLANG_FEEDBACK_TREE_NODE_DELETED_SUCCESS]: ["Tree node deleted successfully", "Baumknoten erfolgreich gelöscht"],
    [Keys.VDLANG_FEEDBACK_TREE_NODE_UPDATED_SUCCESS]: ["Tree node updated successfully", "Baumknoten erfolgreich bearbeitet"],
    [Keys.VDLANG_FEEDBACK_TREE_NODE_ORDER_UPDATED_SUCCESS]: [
        "Tree node order updated successfully",
        "Baumknoten-Sortierung erfolgreich bearbeitet",
    ],
    [Keys.VDLANG_FEEDBACK_CUSTOMFIELD_CREATED]: ["CustomField created successfully", "CustomField erfolgreich hinzugefügt"],
    [Keys.VDLANG_FEEDBACK_CUSTOMFIELD_UPDATED]: ["CustomField updated successfully", "CustomField erfolgreich gespeichert"],
    [Keys.VDLANG_FEEDBACK_CUSTOMFIELD_DELETED]: ["CustomField deleted successfully", "CustomField erfolgreich gelöscht"],
    [Keys.VDLANG_FEEDBACK_CUSTOMVALUE_CREATED]: ["CustomValue created successfully", "CustomValue erfolgreich hinzugefügt"],
    [Keys.VDLANG_FEEDBACK_CUSTOMVALUE_UPDATED]: ["CustomValue updated successfully", "CustomValue erfolgreich gespeichert"],
    [Keys.VDLANG_FEEDBACK_CUSTOMVALUE_DELETED]: ["CustomValue deleted successfully", "CustomValue erfolgreich gelöscht"],
    [Keys.VDLANG_FEEDBACK_DEPARTMENT_CREATED]: ["Department created successfully", "Abteilung erfolgreich hinzugefügt"],
    [Keys.VDLANG_FEEDBACK_DEPARTMENT_UPDATED]: ["Department updated successfully", "Abteilung erfolgreich gespeichert"],
    [Keys.VDLANG_FEEDBACK_DEPARTMENT_DELETED]: ["Department deleted successfully", "Abteilung erfolgreich gelöscht"],
    [Keys.VDLANG_FEEDBACK_WIDGET_CREATED]: ["Widget created successfully", "Widget erfolgreich hinzugefügt"],
    [Keys.VDLANG_FEEDBACK_WIDGET_DUPLICATED]: ["Widget duplicated successfully", "Widget erfolgreich dupliziert"],
    [Keys.VDLANG_FEEDBACK_PARTNER_CREATED]: ["Partner created successfully", "Partner erfolgreich hinzugefügt"],
    [Keys.VDLANG_FEEDBACK_PARTNER_UPDATED]: ["Partner updated successfully", "Partner erfolgreich gespeichert"],
    [Keys.VDLANG_FEEDBACK_SEARCH_TOO_LONG]: ["Search is too long", "Die Suche ist zu lang"],
    [Keys.VDLANG_FEEDBACK_GROUP_CREATED]: ["Group created successfully", "Gruppe erfolgreich hinzugefügt"],
    [Keys.VDLANG_FEEDBACK_GROUP_UPDATED]: ["Group updated successfully", "Gruppe erfolgreich gespeichert"],
    [Keys.VDLANG_FEEDBACK_GROUP_DELETED]: ["Group deleted successfully", "Gruppe erfolgreich gelöscht"],

    [Keys.VDLANG_FEEDBACK_PERMISSION_CREATED]: ["Permission created successfully", "Berechtigung erfolgreich hinzugefügt"],
    [Keys.VDLANG_FEEDBACK_USER_PERMISSION_DELETED]: ["User permission deleted successfully", "Benutzerberechtigung erfolgreich gelöscht"],
    [Keys.VDLANG_FEEDBACK_GROUP_PERMISSION_DELETED]: ["Group permission deleted successfully", "Gruppenberechtigung erfolgreich gelöscht"],
    [Keys.VDLANG_FEEDBACK_DASHBOARD_PERMISSIONS_NEEDED]: [
        "Please note that you may see different values than the dashboard owner due to different access rights.",
        "Beachten Sie, dass Sie aufgrund unterschiedlicher Zugriffsrechte andere Werte als der/die Besitzer/in des Dashboards sehen können.",
    ],
    [Keys.VDLANG_ATTRIBUTE_MIGRATE_TO_TREE]: ["Attribute migrated to tree successfully", "Attribut erfolgreich in Baumstruktur migriert"],
    [Keys.VDLANG_ATTRIBUTE_MIGRATE_TO_TREE_FAILED]: [
        "Attribute migration to tree failed {{errors}}",
        "Attribut-Migration in Baumstruktur fehlgeschlagen: {{errors}}",
    ],
};
