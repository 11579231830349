import { MeasureFieldNames, SubTaskDto } from "api-shared";
import moment from "moment";
import React, { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMeasureFieldDefinitionsQuery } from "../../../domain/filters";
import { useAllUsers } from "../../../domain/users";
import { reportError } from "../../../infrastructure/sentry";
import { trackEvent } from "../../../infrastructure/tracking";
import { parse } from "../../../lib/csv-parse";
import { formatUserFromId } from "../../../lib/formatters.ts";
import { defaultFilename, download } from "../../../lib/trigger-csv-download";
import { translationKeys } from "../../../translations/main-translations";
import { useFieldOptions } from "../../dashboards/reporting/useFieldOptions";
import { useSubTaskQuery } from "../useSubTaskQuery.ts";
import ActivitiesCSVExportDialogContent from "./ActivitiesCSVExportDialogContent";

interface ActivitiesCSVExportDialogProps {
    open: boolean;
    onClose: () => void;
}

function ActivitiesCSVExportDialog({ open, onClose }: Readonly<ActivitiesCSVExportDialogProps>) {
    const [isPrintFormattingEnabled, setIsPrintFormattingEnabled] = useState(false);
    const [isExcelMetadataEnabled, setIsExcelMetadataEnabled] = useState(false);
    const [filename, setFilename] = useState(defaultFilename);
    const fieldDefinitionsQuery = useMeasureFieldDefinitionsQuery();

    const allUsers = useAllUsers();

    const subTaskQuery = useSubTaskQuery();

    const { t: translate } = useTranslation();

    const levelFieldOptions =
        useFieldOptions({
            definitions: fieldDefinitionsQuery.data,
            fieldName: MeasureFieldNames.CurrentGateTaskConfigId,
            // avoid level name being suffixed with process names
            customizeField: (field) => ({ ...field, options: { ...field.options, resolveDuplicates: false } }),
        }) ?? [];

    const getExportData = () =>
        (subTaskQuery.data?.tasks ?? []).flatMap((task: SubTaskDto) => {
            const levelName = levelFieldOptions.find((option) => Number(option.value) === task.measure.currentGateTaskConfigId)?.label;

            return [
                {
                    [translate(translationKeys.VDLANG_ACTIVITIES_CSV_EXPORT_ACTIVITY_ID_COLUMN)]: task.id,
                    [translate(translationKeys.VDLANG_ACTIVITIES_CSV_EXPORT_TITLE_COLUMN)]: task.title,
                    [translate(translationKeys.VDLANG_ACTIVITIES_CSV_EXPORT_STATUS_COLUMN)]: translate(`subtask_status.${task.status}`),
                    [translate(translationKeys.VDLANG_ACTIVITIES_CSV_EXPORT_DUEDATE_COLUMN)]:
                        task.duedate !== null ? moment.utc(task.duedate).format("YYYY-MM-DD") : "",
                    [translate(translationKeys.VDLANG_ACTIVITIES_CSV_EXPORT_RESPONSIBLE_COLUMN)]:
                        task.assignedToId !== null
                            ? formatUserFromId(task.assignedToId, allUsers, { translate })
                            : translate("deleted_user"),
                    [translate(translationKeys.VDLANG_ACTIVITIES_CSV_EXPORT_MEASURE_ID_COLUMN)]: task.measure.clientIid,
                    [translate(translationKeys.VDLANG_ACTIVITIES_CSV_EXPORT_PRIORITY_COLUMN)]: translate(
                        `subtask_priority.${task.priority}`,
                    ),
                    [translate(translationKeys.VDLANG_ACTIVITIES_CSV_EXPORT_LEVEL_COLUMN)]: levelName,
                    [translate(translationKeys.VDLANG_ACTIVITIES_CSV_EXPORT_CREATED_AT_COLUMN)]: moment
                        .utc(task.createdAt)
                        .format("YYYY-MM-DD"),
                    [translate(translationKeys.VDLANG_ACTIVITIES_CSV_EXPORT_CREATED_BY_COLUMN)]:
                        task.createdById !== null ? formatUserFromId(task.createdById, allUsers, { translate }) : translate("deleted_user"),
                    [translate(translationKeys.VDLANG_ACTIVITIES_CSV_EXPORT_UPDATED_AT_COLUMN)]: moment
                        .utc(task.updatedAt)
                        .format("YYYY-MM-DD HH:mm:ss"),
                    [translate(translationKeys.VDLANG_ACTIVITIES_CSV_EXPORT_UPDATED_BY_COLUMN)]:
                        task.updatedById !== null ? formatUserFromId(task.updatedById, allUsers, { translate }) : translate("deleted_user"),
                    [translate(translationKeys.VDLANG_ACTIVITIES_CSV_EXPORT_COMPLETED_AT_COLUMN)]:
                        task.completedAt !== null ? moment.utc(task.completedAt).format("YYYY-MM-DD HH:mm:ss") : "",
                    [translate(translationKeys.VDLANG_ACTIVITIES_CSV_EXPORT_COMPLETED_BY_COLUMN)]:
                        task.completedById !== null ? formatUserFromId(task.completedById, allUsers, { translate }) : "",
                },
            ];
        });

    function exportActivities() {
        trackEvent({ category: "Export", action: "Csv", name: "Activities" });

        const exportData = getExportData();
        const [row] = exportData;
        const generatedColumns = Object.keys(row);

        const fieldOptions = generatedColumns.map((column) => {
            return {
                label: column,
                value: column,
            };
        });

        const options = {
            fields: fieldOptions,
        };

        try {
            const csv = parse(exportData, options, isPrintFormattingEnabled, isExcelMetadataEnabled);
            download(csv, filename);
        } catch (error) {
            reportError(error instanceof Error ? error : new Error("Error generating CSV export"), {
                extras: { cause: JSON.stringify(error) },
            });
        }
    }

    return (
        <ActivitiesCSVExportDialogContent
            open={open}
            printFormatting={isPrintFormattingEnabled}
            toggleAddExcelMetadata={isExcelMetadataEnabled}
            subtasks={subTaskQuery.data?.tasks}
            dataAvailable={!subTaskQuery.isFetching}
            translate={translate}
            startExport={exportActivities}
            closeDialog={() => {
                onClose();
            }}
            filename={filename}
            handleChangePrintFormatting={(event: ChangeEvent<HTMLInputElement>) => setIsPrintFormattingEnabled(event.target.checked)}
            handleChangeAddExcelMetadata={(event: ChangeEvent<HTMLInputElement>) => setIsExcelMetadataEnabled(event.target.checked)}
            handleChangeFilename={(event: ChangeEvent<HTMLInputElement>) => setFilename(event.target.value)}
        />
    );
}

export default React.memo(ActivitiesCSVExportDialog);
