import { Box, Button, Container, FormGroup, Grid, styled, Typography } from "@mui/material";
import { TokenValidationDto } from "api-shared";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import Form from "../../components/Form";
import SetPasswordInput from "../../components/input/SetPasswordInput";
import LoadingAnimation from "../../components/loading/LoadingAnimation";
import PrivacyPolicyLink from "../../components/PrivacyPolicyLink";
import SplitLayout from "../../components/SplitLayout";
import { useConfirmInvite, useValidateInviteToken } from "../../domain/password-reset";
import { useLanguageChange } from "../../domain/translation";
import { showNotificationEvent } from "../../infrastructure/notifications";
import { LoginData } from "../../lib/api";
import { useLoginMutation } from "../../lib/authentication-saga";
import { NotificationType } from "../../lib/notifications";
import background from "../../static/images/hello_illustration.svg";
import logo from "../../static/images/logo_blau.svg";
import { isLanguage, translationKeys } from "../../translations/main-translations";
import { FeedbackTranslationKeys } from "../../translations/notification-translations";
import PageNotFound from "../error/PageNotFound";
import TokenExpired from "./TokenExpired";

const HeaderGrid = styled(Grid)(({ theme }) => ({
    // Height needs to be 0, so that this item grows as mich as the footer item
    height: 0,
    flexGrow: 1,
    [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
    },
}));

const ContentGrid = styled(Grid)(({ theme }) => ({
    width: "100%",
    flexGrow: 0,
    [theme.breakpoints.down("sm")]: {
        textAlign: "center",
    },
}));

const LogoImage = styled("img")({
    width: 256,
    height: "100%",
});

const InviteConfirm = () => {
    const { t: translate } = useTranslation();
    const changeLanguage = useLanguageChange();
    const navigate = useNavigate();

    const confirmInviteMutation = useConfirmInvite();
    // Validation needs to be a mutation, because it has side effects but its return value is also needed for rendering
    const [validationResult, setValidationResult] = useState<TokenValidationDto | null>(null);
    const validateInviteTokenMutation = useValidateInviteToken(setValidationResult);
    const validateInviteToken = validateInviteTokenMutation.mutate;
    const loginMutation = useLoginMutation();
    const dispatch = useDispatch();

    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");
    const language = searchParams.get("language");

    useEffect(() => {
        token != null && validateInviteToken(token);
    }, [validateInviteToken, token]);

    useEffect(() => {
        isLanguage(language) && changeLanguage(language);
    }, [changeLanguage, language]);

    const [password, setPassword] = useState("");
    const [isPasswordValid, setIsPasswordValid] = useState(false);

    function updatePassword(updatedPassword: string, updatedIsPasswordValid: boolean) {
        setPassword(updatedPassword);
        setIsPasswordValid(updatedIsPasswordValid);
    }

    const loginAndRedirect = async (email: string, password: string): Promise<void> => {
        try {
            await loginMutation.mutateAsync(new LoginData(email, password));
            // redirect after successful login to avoid redirect to login page because user is not authenticated (yet)
            navigate("/");
        } catch {
            // Auto-login is not critical, so just ignore any errors caused by the mutation
        }
    };

    function onSubmit() {
        if (isPasswordValid && token != null) {
            confirmInviteMutation.mutate(
                { token, password },
                {
                    onSuccess: ({ email }, { password }) => {
                        loginAndRedirect(email, password);
                    },
                    onError: () => {
                        dispatch(
                            showNotificationEvent(
                                NotificationType.ERROR,
                                FeedbackTranslationKeys.VDLANG_FEEDBACK_USER_DEACTIVATED_OR_DELETED,
                            ),
                        );
                    },
                },
            );
        }
    }

    if (validateInviteTokenMutation.isLoading || validateInviteTokenMutation.isIdle) {
        return <LoadingAnimation />;
    }

    if (!validateInviteTokenMutation.isSuccess) {
        return <PageNotFound />;
    }

    if (validationResult != null && !validationResult.isValid) {
        return <TokenExpired translate={translate} />;
    }

    const greeting =
        validationResult?.displayname != null ? (
            <Typography variant="h4" mb={2}>
                {translate(translationKeys.VDLANG_INVITE_GREETING, { name: validationResult.displayname })}
            </Typography>
        ) : null;

    return (
        <SplitLayout image={background}>
            <Container fixed maxWidth="xs" sx={{ height: "100%" }}>
                <Grid container direction="column" sx={{ height: "100%" }}>
                    <HeaderGrid item container alignItems="center">
                        <LogoImage src={logo} alt="Valuedesk" />
                    </HeaderGrid>
                    <ContentGrid item>
                        {greeting}
                        <Typography mb={2}>{translate(translationKeys.VDLANG_INVITE_INSTRUCTIONS)}</Typography>
                        <Form onSubmit={onSubmit}>
                            <FormGroup>
                                <SetPasswordInput size="extralarge" onPasswordChange={updatePassword} />
                                <Typography variant="caption" pt={1} pb={3}>
                                    {translate(translationKeys.VDLANG_PASSWORD_REQUIREMENTS)}
                                </Typography>
                            </FormGroup>
                        </Form>
                        <Button fullWidth color="primary" variant="contained" size="large" disabled={!isPasswordValid} onClick={onSubmit}>
                            {translate(translationKeys.VDLANG_INVITE_SET_PASSWORD_BUTTON)}
                        </Button>
                    </ContentGrid>
                    <Box flexGrow={1} pb={3} />
                    <Grid container item direction="column-reverse" flexGrow={1} pb={3}>
                        <Typography align="center" variant="caption">
                            <PrivacyPolicyLink color="textSecondary" />
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </SplitLayout>
    );
};

export default React.memo(InviteConfirm);
