import MuiInfoIcon from "@mui/icons-material/InfoRounded";
import MuiInfoIconOutlined from "@mui/icons-material/InfoOutlined";
import { styled, SvgIconProps } from "@mui/material";
import React from "react";
import withTooltip from "../../hocs/withTooltip";

const StyledInfoIcon = styled(MuiInfoIcon)(() => ({
    verticalAlign: "-0.125em",
}));

const StyledInfoIconOutlined = styled(MuiInfoIconOutlined)(() => ({
    verticalAlign: "-0.125em",
}));

type InfoIconProps = SvgIconProps & { outlined?: boolean };

const InfoIcon = React.forwardRef<SVGSVGElement, InfoIconProps>(({ outlined = false, ...props }, ref) =>
    outlined ? (
        <StyledInfoIconOutlined fontSize="inherit" {...props} ref={ref} />
    ) : (
        <StyledInfoIcon fontSize="inherit" {...props} ref={ref} />
    ),
);

export default withTooltip(InfoIcon);
