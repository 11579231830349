import { nonNullable } from "api-shared";
import { useCallback } from "react";
import { createFilter } from "react-select";
import { ISelectProps, Option } from "../types";

const MAX_OPTIONS_COUNT = 1000;

const defaultFilterFunction = createFilter({
    // this function is run for every option and every keystroke. Use any here to do a fast but still safe access without needing to do
    // more expensive ("id" in o) for each attribute at runtime
    // Note: at this stage, label/value are not resolved when using custom accessors, so id/name need to be put in here to allow filtering
    // for all field comboboxes
    stringify: ({ label, name, primaryLabel, secondaryLabel }: Option<unknown> & { name?: string }) =>
        [label, name, primaryLabel, secondaryLabel].filter(nonNullable).join(" "),
});

export const useAsyncSelectOptions = <OptionType extends Option, IsMulti extends boolean>({
    options,
    disableFilter = false,
}: ISelectProps<OptionType, IsMulti>) => {
    const hasAsyncEnabled = !disableFilter && options !== undefined && options.length > MAX_OPTIONS_COUNT;

    const loadOptions = useCallback(
        // react-select requires a promise, and async functions wrap return value in a promise, which is convenient here with many returns
        // eslint-disable-next-line @typescript-eslint/require-await
        async (inputValue: string) => {
            if (options === undefined) {
                // This should not happen
                return [];
            }

            if (hasAsyncEnabled && inputValue.length > 0 && inputValue.length <= 2) {
                // avoid filtering large options sets with too small search keys
                return [];
            }

            let filteredOptions = options;
            if (inputValue.length > 0 && !disableFilter) {
                filteredOptions = options.filter((option) => {
                    if ("options" in option) {
                        // This is an option group, do not support filtering here as there is currently no use of option groups in Valudesk
                        return false;
                    }
                    // defaultFilterFunction expects a FilterOptionOption type as parameter, which needs to have an additional data prop
                    // option is a single option => use default filter function directly
                    return defaultFilterFunction({ ...option, data: {} }, inputValue);
                });
            }
            // pass at most MAX_OPTIONS + 1 options to the select
            // the case MAX_OPTIONS + 1 indicates, that there are options hidden, so SelectMenuList can show the message for that case
            return filteredOptions.slice(0, MAX_OPTIONS_COUNT + 2); // end is exclusive
        },
        [options, disableFilter, hasAsyncEnabled],
    );

    return {
        loadOptions,
        maxOptionsCount: MAX_OPTIONS_COUNT,
        // defaultOptions = true calls loadOptions initially with inputValue "" for getting the option set,
        // otherwise the provided options are used
        defaultOptions: hasAsyncEnabled ? true : options,
    };
};
