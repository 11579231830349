import { ClientDto, UserDto } from "api-shared";
import React, { PropsWithChildren } from "react";

const AuthenticatedContext = React.createContext<IAuthenticatedContext | undefined>(undefined);

export const AuthenticatedContextProvider = ({ client, user, children }: PropsWithChildren<IAuthenticatedContext>) => {
    const value = React.useMemo(() => ({ client, user }), [client, user]);
    return <AuthenticatedContext.Provider value={value}>{children}</AuthenticatedContext.Provider>;
};

interface IAuthenticatedContext {
    client: ClientDto;
    user: UserDto | null;
}

/**
 * Hook for easy access to the context's value. Throws an error if not used inside the context.
 *
 * @returns {IAuthenticatedContext}
 */
export const useAuthenticatedContext = (): IAuthenticatedContext => {
    const context = React.useContext(AuthenticatedContext);
    if (context === undefined) {
        throw new Error("AuthenticatedContextProvider missing");
    }
    return context;
};
