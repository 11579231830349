import * as Sentry from "@sentry/react";
import { UserDto } from "api-shared";
import moment from "moment";
// other locales than en must be added manually
// /dist/ is needed so locales are also imported correctly with vite, see https://github.com/vitejs/vite/issues/5359
import { QueryClient } from "@tanstack/react-query";
// @ts-expect-error noUncheckedSideEffectImports complains here, but there's no alternative way to import that works
import "moment/dist/locale/de";
import { AllEffect, call, CallEffect, put, PutEffect, SelectEffect, takeEvery } from "redux-saga/effects";
import { changeLanguageAction } from "../domain/translation";
import { makeCurrentUserQuery, UPDATE_USER_PROFILE_SUCCEEDED } from "../domain/users";
import { showNotificationEvent } from "../infrastructure/notifications";
import { matomoSetAnonymousSession, matomoSetUserSession } from "../infrastructure/tracking";
import { NotificationType } from "../lib/notifications";
import { setupTranslations } from "../translations";
import { defaultLanguage as applicationDefaultLanguage, Language, languages, translationKeys } from "../translations/main-translations";
import { ActionType } from "../types/helpers";
import { SIGN_IN_SUCCEEDED, SIGN_OUT_SUCCEEDED } from "./authentication-saga";
import { isUnauthenticatedRoute, RouteFor } from "./routes";

export const BOOTSTRAP = "BOOTSTRAP";
export const BOOTSTRAP_STARTED = "BOOTSTRAP_STARTED";
export const BOOTSTRAP_SUCCEEDED = "BOOTSTRAP_SUCCEEDED";
export const BOOTSTRAP_FAILED = "BOOTSTRAP_FAILED";

export const LOCAL_STORAGE_NEW_VERSION_AVAILABLE_KEY = "newVersion";

export function bootstrapEvent(queryClient: QueryClient) {
    return {
        type: BOOTSTRAP,
        queryClient,
    };
}

export function boostrapStartedEvent() {
    return {
        type: BOOTSTRAP_STARTED,
    };
}

function estimateBrowserLanguage() {
    return navigator.language ?? null;
}

export function getDefaultLanguage() {
    return estimateBrowserLanguage() ?? applicationDefaultLanguage;
}

export function getLanguageCode(language: string) {
    const detectedCode = language.slice(0, 2) as Language;
    // check if language is supported, fallback to default language otherwise
    return languages.indexOf(detectedCode) >= 0 ? detectedCode : applicationDefaultLanguage;
}

function* configureLanguage(language: string) {
    const languageCode = getLanguageCode(language);
    yield put(changeLanguageAction(languageCode));
    moment.locale(languageCode);
}

function tryGetCurrentUser(queryClient: QueryClient) {
    // Handle error response gracefully, because they only indicate that the user is not logged in (anymore)
    return queryClient.fetchQuery(makeCurrentUserQuery()).catch(() => null);
}

function* configureUserSettings(currentUser: UserDto) {
    // set application to use user's selected language
    if (currentUser.language) {
        yield call(configureLanguage, currentUser.language);
    }
}

function* showNotificationOnNewVersion() {
    const isNewVersion = localStorage.getItem(LOCAL_STORAGE_NEW_VERSION_AVAILABLE_KEY);
    if (isNewVersion) {
        localStorage.removeItem(LOCAL_STORAGE_NEW_VERSION_AVAILABLE_KEY);
        yield put(showNotificationEvent(NotificationType.UPDATE, translationKeys.VDLANG_NEW_VALUEDESK_VERSION_HINT));
    }
}

function* updateConfigs(action: ActionType<typeof UPDATE_USER_PROFILE_SUCCEEDED, { response: UserDto }>) {
    yield call(configureUserSettings, action.response);
}

function* initializeSession(currentUser: UserDto): Generator<SelectEffect | AllEffect<unknown> | CallEffect, void, any> {
    // perform all tasks that should be done when the user is authenticated
    matomoSetUserSession(currentUser.id, currentUser.clientId);
    Sentry.setUser({ id: String(currentUser.id), clientId: String(currentUser.clientId) });

    yield call(showNotificationOnNewVersion);

    yield call(configureUserSettings, currentUser);
}

export function* bootstrapSaga() {
    yield takeEvery([BOOTSTRAP, SIGN_IN_SUCCEEDED, SIGN_OUT_SUCCEEDED], bootstrap);
    yield takeEvery(UPDATE_USER_PROFILE_SUCCEEDED, updateConfigs);
}

type BootstrapActionType = ReturnType<typeof bootstrapEvent>;

export function* bootstrap({ queryClient }: BootstrapActionType): Generator<PutEffect | CallEffect | SelectEffect, void, any> {
    yield put(boostrapStartedEvent());

    // Make sure to start with a clean cache, because bootstrapping has multiple triggering actions
    // Needs to be done early so that getCurrentUser call below will be kept in cache
    queryClient.clear();

    yield call(setupTranslations, queryClient);

    // detect language and configure it
    yield call(configureLanguage, getDefaultLanguage());

    const { pathname } = window.location;

    if (!isUnauthenticatedRoute(pathname) || pathname === RouteFor.user.login) {
        const currentUser: UserDto | null = yield call(tryGetCurrentUser, queryClient);

        if (currentUser !== null) {
            yield call(initializeSession, currentUser);
            yield call(configureUserSettings, currentUser);
        }
    } else {
        // Anonymous user tracking for matomo
        matomoSetAnonymousSession();
        Sentry.getCurrentScope().setUser(null);
    }

    yield put({
        type: BOOTSTRAP_SUCCEEDED,
    });
}
