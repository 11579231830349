import QuestionAnswerRounded from "@mui/icons-material/QuestionAnswerRounded";
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Skeleton,
    Stack,
    Typography,
    buttonClasses,
    styled,
    svgIconClasses,
} from "@mui/material";
import { FeedEntryDto, FeedEntryEmbeddedObjectType, ReactionType, SYSTEM_USER_ID, formatUserName } from "api-shared";
import { TFunction } from "i18next";
import { mapValues } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import ReactionButton from "../../components/reactions/ReactionButton";
import ReactionSummary from "../../components/reactions/ReactionSummary";
import UserAvatar from "../../components/user/UserAvatar";
import UserEntryWithPopup from "../../components/user/UserEntryWithPopup";
import { CommentParentType, useComments } from "../../domain/comment";
import { useFeedEntryReactionMutation } from "../../domain/feed";
import { useAllUsers, useCurrentUser } from "../../domain/users";
import useTimezone from "../../hooks/useTimezone";
import { trackEvent } from "../../infrastructure/tracking";
import { tryTranslate } from "../../lib/translate";
import { translationKeys } from "../../translations/main-translations";
import FeedEntryCommentList from "./FeedEntryCommentList";
import EmbeddedIdea from "./embedded/EmbeddedIdea";
import EmbeddedMeasure from "./embedded/EmbeddedMeasure";
import EmbeddedUserEvent from "./embedded/EmbeddedUserEvent";

export const SHOW_ON_PARENT_HOVER_CLASS_NAME = "showOnParentHover";

const EmbeddedCard = styled(Card)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.default,
    ["@media (hover: hover)"]: {
        [`& .${SHOW_ON_PARENT_HOVER_CLASS_NAME}`]: {
            opacity: 0,
            transition: theme.transitions.create("opacity"),
        },
        "&:hover": {
            [`& .${SHOW_ON_PARENT_HOVER_CLASS_NAME}`]: {
                opacity: 1,
            },
        },
    },
}));

const EmbeddedCardWithShadow = styled(Card)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.default,
    boxShadow: "inset 0 -32px 32px -48px black",
    ["@media (hover: hover)"]: {
        [`& .${SHOW_ON_PARENT_HOVER_CLASS_NAME}`]: {
            opacity: 0,
            transition: theme.transitions.create("opacity"),
        },
        "&:hover": {
            [`& .${SHOW_ON_PARENT_HOVER_CLASS_NAME}`]: {
                opacity: 1,
            },
        },
    },
}));

interface IEmbeddedContentProps {
    feedEntry: FeedEntryDto;
    translate: TFunction;
}

const ReactionIconMap = new Map<ReactionType, { icon: string; translationKey: string }>([
    [ReactionType.Thumbsup, { icon: "👍", translationKey: translationKeys.VDLANG_REACTION_EMOJI_LIKE }],
    [ReactionType.Thinkingface, { icon: "🤔", translationKey: translationKeys.VDLANG_REACTION_EMOJI_THINKING }],
    [ReactionType.Flexedbiceps, { icon: "💪", translationKey: translationKeys.VDLANG_REACTION_EMOJI_STRONG }],
    [ReactionType.Partypopper, { icon: "🎉", translationKey: translationKeys.VDLANG_REACTION_EMOJI_PARTY }],
    [ReactionType.Lightbulb, { icon: "💡", translationKey: translationKeys.VDLANG_REACTION_EMOJI_LIGHTBULB }],
]);

const hasOverflow = (element: HTMLElement) => element.offsetHeight < element.scrollHeight;

const EmbeddedContent = ({ feedEntry, translate }: IEmbeddedContentProps) => {
    const [isOverflowing, setIsOverflowing] = useState(false);
    const subject = `FeedEntrySubject.${feedEntry.subject.translationKey}`;
    const subjectParams = mapValues(feedEntry.subject.params, (value) => {
        if (value === undefined) {
            return;
        }

        return tryTranslate(translate, value);
    });
    const cardRef = useRef<HTMLDivElement>(null);

    const overflow = useCallback(() => {
        if (cardRef.current === undefined || cardRef.current === null) {
            return;
        }
        const newOverflow = hasOverflow(cardRef.current) || (cardRef instanceof HTMLElement && hasOverflow(cardRef));
        setIsOverflowing(newOverflow);
    }, []);

    useEffect(() => {
        overflow();
    }, [overflow, cardRef.current?.offsetHeight]);

    const CardComponent = isOverflowing ? EmbeddedCardWithShadow : EmbeddedCard;
    switch (feedEntry.embeddedObjectType) {
        case FeedEntryEmbeddedObjectType.Measure:
            return (
                <>
                    <Typography variant="body2" sx={{ pb: 1 }}>
                        <Trans i18nKey={subject} values={subjectParams} />
                    </Typography>
                    <CardComponent sx={{ mb: 1 }} ref={cardRef}>
                        <EmbeddedMeasure id={feedEntry.embeddedObjectId} />
                    </CardComponent>
                </>
            );
        case FeedEntryEmbeddedObjectType.UserEvent:
            return (
                <CardComponent sx={{ mb: 1 }} ref={cardRef}>
                    <EmbeddedUserEvent id={feedEntry.embeddedObjectId} text={<Trans i18nKey={subject} values={subjectParams} />} />
                </CardComponent>
            );

        case FeedEntryEmbeddedObjectType.Opportunity:
            return (
                <>
                    <Typography variant="body2" sx={{ pb: 1 }}>
                        <Trans i18nKey={subject} values={subjectParams} />
                    </Typography>
                    <CardComponent sx={{ mb: 1 }} ref={cardRef}>
                        <EmbeddedIdea id={feedEntry.embeddedObjectId} />
                    </CardComponent>
                </>
            );
        default:
            return null;
    }
};

const CommentButton = styled(Button)(({ theme }) => ({
    color: theme.palette.text.primary,
    [`& .${buttonClasses.startIcon}`]: {
        marginRight: theme.spacing(0.5),
    },
    [`& .${svgIconClasses.root}`]: {
        fontSize: theme.typography.body1.fontSize,
    },
}));

const FeedEntryCardContent = styled(CardContent)(({ theme }) => ({
    "&:last-child": {
        paddingBottom: theme.spacing(1),
    },
}));

const CommentButtonIcon = styled(QuestionAnswerRounded)(({ theme }) => ({
    color: theme.palette.action.active,
}));

interface IFeedEntryProps {
    feedEntry: FeedEntryDto;
    parentFeedPageSize?: number;
}

const FeedEntry = ({ feedEntry, parentFeedPageSize }: IFeedEntryProps) => {
    const { t: translate } = useTranslation();
    const { formatDateTime } = useTimezone();

    const [showComments, setShowComments] = useState(false);
    const commentsQuery = useComments({
        parentType: CommentParentType.FEED,
        parentId: feedEntry.id,
    });

    const users = useAllUsers();
    const user = users.find((u) => u.id === feedEntry.authorId);
    const currentUser = useCurrentUser();

    const isSystemUser = feedEntry.authorId === SYSTEM_USER_ID;
    const changeReactionMutation = useFeedEntryReactionMutation(parentFeedPageSize, {
        id: feedEntry.embeddedObjectId,
        type: feedEntry.embeddedObjectType,
    });
    const currentUserReaction = feedEntry.reactions.find((r) => r.userId === currentUser?.id)?.type ?? undefined;

    const userNameString = user !== undefined ? formatUserName(user, { translate, withAdornment: false }) : undefined;

    function toggleShowComments() {
        setShowComments((show) => {
            const newValue = !show;
            if (newValue === true) {
                trackEvent({ category: "Feed", action: "Feed Comment Seen" });
            }
            return newValue;
        });
    }

    return (
        <Card component="article" sx={{ px: 1 }}>
            <CardHeader
                avatar={
                    isSystemUser ? (
                        <UserAvatar size={40} isSystemUser={isSystemUser} />
                    ) : (
                        <UserEntryWithPopup avatarProps={{ size: 40 }} iconOnly user={user} />
                    )
                }
                title={isSystemUser ? translate(translationKeys.VDLANG_SYSTEM_USER) : userNameString}
                titleTypographyProps={{ fontWeight: "medium" }}
                subheader={formatDateTime(feedEntry.createdAt)}
                subheaderTypographyProps={{ variant: "caption" }}
                sx={{ pb: 0 }}
            />
            <FeedEntryCardContent>
                <EmbeddedContent feedEntry={feedEntry} translate={translate} />
                {commentsQuery.isSuccess && (
                    <FeedEntryCommentList feedEntryId={feedEntry.id} comments={commentsQuery.data} showComments={showComments} />
                )}
                <Stack direction="row" justifyContent="space-between">
                    <Stack direction="row" spacing={1} alignItems="center">
                        <ReactionButton
                            currentUserReaction={currentUserReaction}
                            reactionConfig={ReactionIconMap}
                            onReactionChange={(type) => changeReactionMutation.mutate({ type, feedEntryId: feedEntry.id })}
                        />
                        {feedEntry.reactions.length > 0 ? (
                            <>
                                <Divider orientation="vertical" sx={{ height: (theme) => theme.spacing(2) }} />
                                <ReactionSummary allUserReactions={feedEntry.reactions} reactionConfig={ReactionIconMap} />
                            </>
                        ) : null}
                    </Stack>
                    {commentsQuery.isSuccess ? (
                        <CommentButton size="small" startIcon={<CommentButtonIcon />} variant="text" onClick={toggleShowComments}>
                            {translate(translationKeys.VDLANG_COMMENT_ACTION)} ({commentsQuery.data.length})
                        </CommentButton>
                    ) : (
                        <Skeleton width="20%" />
                    )}
                </Stack>
            </FeedEntryCardContent>
        </Card>
    );
};

export default FeedEntry;
