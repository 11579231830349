/* eslint-disable sonarjs/no-duplicate-string */
import {
    AttributeTitle,
    EffectField,
    EffectFieldTypeModifier,
    EffectFilterDateField,
    GlobalCalculationIdentifier,
    IdeaFieldNames,
    MeasureFieldNames,
    mergeCamelized,
} from "api-shared";

type TranslationMap = Record<string, [string, string]>;

const basicEffectFields: TranslationMap = {
    pl: ["Period of Effectiveness", "Zeitraum der Wirksamkeit"],
    [mergeCamelized("pl", "hint")]: [
        "The time period over which the effect is distributed. The period should start on the day from which the {{processName}} is effective for the first time.",
        "Der Zeitraum, über den der Effekt verteilt wird. Der Zeitraum sollte an dem Tag beginnen, ab da an die {{processName}} das erste Mal wirksam ist.",
    ],

    [EffectField.StartDate]: ["Start of Effectiveness", "Beginn der Wirksamkeit"],
    [EffectField.EndDate]: ["End of Effectiveness", "Ende der Wirksamkeit"],
    [EffectField.Initial]: ["Initial Value", "Ausgangswert"],
    [mergeCamelized(EffectField.Initial, "hint")]: [
        "The initial value is the reference for the Savings resp. Extra Costs",
        "Der Ausgangswert ist die Referenz für die Einsparungen bzw. Mehrkosten",
    ],

    [EffectField.PriceHike]: ["Expected Price Increase", "Erwartete Preiserhöhung"],
    [mergeCamelized(EffectField.PriceHike, "hint")]: [
        "Additional costs to the initial value that would occur if the {{processName}} will not be done. Capture this value so that the Cost Avoidance can be calculated.",
        "Zusätzliche Kosten zum Ausgangswert, die entstehen würden, wenn die {{processName}} nicht durchgeführt werden würde. Erfassen Sie diesen Wert, um die abgewehrten Mehrkosten berechnen zu können.",
    ],
    [EffectField.Target]: ["Target Value", "Zielwert"],
    [mergeCamelized(EffectField.Target, "hint")]: [
        "The target value is the desired value after the {{processName}} is effective.",
        "Der Zielwert ist der angestrebte Wert, nachdem die {{processName}} wirksam ist.",
    ],
    [EffectField.Effect]: ["Potential", "Potenzial"],
    [mergeCamelized(EffectFieldTypeModifier.Cumulated, EffectField.Effect)]: ["Total Potential", "Gesamtpotenzial"],
    [mergeCamelized(EffectFieldTypeModifier.Recurring, EffectField.Effect)]: [
        "Recurring Potential (Recurring Period)",
        "Wiederkehrendes Potenzial (Wiederkehrender Zeitraum)",
    ],
    [EffectFilterDateField.EndOfRecurrence]: ["End of Recurrence", "Ende der Wiederkehr"],
    costAvoidance: ["Cost Avoidance", "Abgewehrte Mehrkosten"],
};

const levelEffectFields: TranslationMap = Object.entries(basicEffectFields).reduce(
    (translations, [fieldName, [en, de]]) => ({
        ...translations,
        [mergeCamelized(GlobalCalculationIdentifier.Plan, fieldName)]: [`Plan - ${en}`, `Plan - ${de}`],
        [mergeCamelized(GlobalCalculationIdentifier.Forecast, fieldName)]: [`FC - ${en}`, `FC - ${de}`],
        [mergeCamelized(GlobalCalculationIdentifier.Actual, fieldName)]: [`ACT - ${en}`, `ACT - ${de}`],
    }),
    {} as TranslationMap,
);

const totalEffectFields: TranslationMap = Object.entries({ ...basicEffectFields, ...levelEffectFields }).reduce(
    (translations, [fieldName, [en, de]]) => ({
        ...translations,
        [mergeCamelized("total", fieldName)]: [en, de],
    }),
    {} as TranslationMap,
);

export const IDEA_FIELD_PREFIX = "idea";

const ideaFields: TranslationMap = {
    [IdeaFieldNames.DisplayId]: ["ID", "ID"],
    [IdeaFieldNames.Title]: ["Title", "Titel"],
    [IdeaFieldNames.Description]: ["Description", "Beschreibung"],
    [IdeaFieldNames.OwnerId]: ["Responsible person", "Verantwortliche Person"],
    [IdeaFieldNames.CreatorId]: ["Creator", "Ersteller"],
    [IdeaFieldNames.CreatedAt]: ["Creation date", "Erstellungsdatum"],
    [IdeaFieldNames.LastModificationAt]: ["Last modification", "Letzte Änderung"],
    [IdeaFieldNames.EffortEstimate]: ["Effort estimation", "Geschätzter Aufwand"],
    [IdeaFieldNames.PotentialEstimate]: ["Estimated potential in {{clientCurrency}}", "Geschätztes Potential in {{clientCurrency}}"],
    [IdeaFieldNames.TimeEstimate]: ["Estimated implementation period in months", "Geschätzter Umsetzungszeitraum in Monaten"],
    [IdeaFieldNames.ReactionsCount]: ["Number of reactions", "Anzahl Reaktionen"],
    [IdeaFieldNames.DiscardReason]: ["Discard reason", "Verwerfungsgrund"],
    [IdeaFieldNames.DiscardStatement]: ["Discard statement", "Verwerfungskommentar"],
    [IdeaFieldNames.Status]: ["Status", "Status"],
};

export const fieldTranslations = {
    [MeasureFieldNames.ClientIid]: ["ID", "ID"],
    title: ["Title", "Titel"],
    titleHint: [
        "Increase the awareness of your idea for a new measure in which you formulate the core of the change in a generally understandable way.",
        "Erhöhen Sie die Aufmerksamkeit zu Ihrer Idee einer neuen Maßnahme, in dem Sie den Kern der Veränderung allgemein verständlich formulieren.",
    ],
    type: ["Process Type", "Prozesstyp"],
    methodSegment: ["Method Segment", "Methodensegment"],
    status: ["Status", "Status"],
    [AttributeTitle.ProductGroupId]: ["Material Group", "Warengruppe"],
    [mergeCamelized(AttributeTitle.ProductGroupId, "hint")]: [
        "Which internal commodity group is affected by this measure? If several commodity groups are affected and the distinction is relevant, please prepare separate measures for these.",
        "Welche interne Warengruppe ist von dieser Maßnahme betroffen? Falls mehrere Warengruppen betroffen sind und die Unterscheidung ist von Relevanz, erstellen Sie bitte dafür getrennte Maßnahmen.",
    ],
    [AttributeTitle.CurrentSupplier]: ["Current Partners", "Aktuelle Partner"],
    [mergeCamelized(AttributeTitle.CurrentSupplier, "hint")]: [
        "Please select the current partners for this measure.",
        "Bitte wählen Sie die derzeitigen Partner aus.",
    ],
    [mergeCamelized(AttributeTitle.CurrentSupplier, "postcapture", "hint")]: [
        "Please select the current partners for this postcapture.",
        "Bitte wählen Sie die derzeitigen Partner dieser Nacherfassung aus.",
    ],
    [AttributeTitle.NewSupplier]: ["New Partners", "Neue Partner"],
    [mergeCamelized(AttributeTitle.NewSupplier, "hint")]: [
        "If a change of partners is planned, please select the future partners here.",
        "Falls ein Partnerwechsel geplant ist, wählen Sie hier bitte den, oder die zukünftigen Partner aus.",
    ],
    [AttributeTitle.OriginatorClient]: ["Source of Idea", "Ideen-Herkunft"],
    [mergeCamelized(AttributeTitle.OriginatorClient, "hint")]: [
        "When you create an idea, {{clientName}} is preselected. In the case of external ideas, the company is automatically selected as the source. You can change this here manually.",
        "Wenn Sie eine Idee anlegen, ist {{clientName}} vorausgewählt. Bei externen Ideen wird automatisch das Unternehmen als Herkunft hinterlegt. Sie können dies aber hier manuell ändern.",
    ],
    company: ["Companies", "Gesellschaften"],
    [MeasureFieldNames.Currencies]: ["Currencies", "Währungen"],
    [`${MeasureFieldNames.Currencies}_one`]: ["Currency", "Währung"],
    valueLever: ["Method Type", "Methodentyp"],
    [AttributeTitle.CostLeverId]: ["Method", "Methode"],
    [mergeCamelized(AttributeTitle.CostLeverId, "hint")]: [
        "Specify the method that you use for this measure. The selectable methods are based on the previously selected method type.",
        "Konkretisieren Sie die Methode, die Sie für diese Maßnahme anwenden. Die auswählbaren Methoden basieren auf den zuvor ausgewählten Methodentypen.",
    ],
    [mergeCamelized(AttributeTitle.CostLeverId, "postcapture", "hint")]: [
        "Specify the method that you use for this Post-Capture. The selectable methods are based on the previously selected method type.",
        "Konkretisieren Sie die Methode, die Sie für diese Nacherfassung anwenden. Die auswählbaren Methoden basieren auf dem zuvor ausgewählten Methodentypen.",
    ],
    location: ["Location", "Standort"],
    [AttributeTitle.Project]: ["Tags", "Schlagworte"],
    [mergeCamelized(AttributeTitle.Project, "hint")]: [
        "Add one or more keywords that you want to use in your evaluations. You can use it to filter in Grid and Desk. All tags are visible organization-wide.",
        "Fügen Sie ein oder mehrere Schlagworte hinzu, die Sie in Ihren Auswertungen verwenden möchten. Sie können damit in der Tabellenansicht und der Desk-Ansicht filtern. Alle Schlagwörter sind unternehmensweit sichtbar.",
    ],
    [AttributeTitle.CompanyId]: ["Company", "Gesellschaft"],
    companySplit: ["Company/Location", "Gesellschaft/Standort"],
    companySplitHint: [
        "Which of your companies would feel the financial effect of the measure?",
        "Bei welcher Ihrer Gesellschaften wird der Finanzeffekt der Maßnahme eintreffen?",
    ],
    companySplitPostcaptureHint: [
        "Which of the companies are affected by the financial effect of this Post-Capture?",
        "Bei welcher Ihrer Gesellschaften trifft der Finanzeffekt der Nacherfassung ein?",
    ],
    assignedToId: ["Responsible person", "Verantwortliche*r"],
    createdAt: ["Created at", "Erstellt am"],
    updatedAt: ["Last Modified", "Letzte Änderung"],
    lastModificationAt: ["Last Modified", "Letzte Änderung"],
    lastViewedAt: ["Last viewed by anyone", "Zuletzt von jemandem angesehen"],
    myLastViewedAt: ["Last viewed by me", "Zuletzt von mir angesehen"],
    subtasks: ["Number of activities", "Anzahl Aktivitäten"],
    openSubtasks: ["Number of open activities", "Anzahl offener Aktivitäten"],
    unassignedSubtasks: ["Number of activities without responsible person", "Anzahl Aktivitäten ohne zugeordnete Person"],
    estimatedEffort: ["Estimated effort (total)", "Geschätzter Aufwand (gesamt)"],
    effortEstimate: ["Effort estimation", "Geschätzter Aufwand"],
    trackedTime: ["Tracked time (total)", "Erfasste Zeit (gesamt)"],
    estimatedEffortImplementation: ["Estimated effort (implementation)", "Geschätzter Aufwand (Umsetzung)"],
    trackedTimeImplementation: ["Tracked time (implementation)", "Erfasste Zeit (Umsetzung)"],
    planEfficiency: ["PLAN - Efficiency ({{clientCurrency}}/h)", "PLAN - Effizienz ({{clientCurrency}}/h)"],
    forecastEfficiency: ["FC - Efficiency ({{clientCurrency}}/h)", "FC - Effizienz ({{clientCurrency}}/h)"],
    overdueSubtasks: ["Number of overdue activities", "Anzahl überfälliger Aktivitäten"],
    completionDate: ["Completion Date", "Abschlussdatum"],
    [AttributeTitle.DiscardReason]: ["Discard Reason", "Verwerfungsgrund"],
    [AttributeTitle.DiscardStatement]: ["Discard Statement", "Verwerfungskommentar"],
    createdBy: ["Created By", "Angelegt von"],
    createdByName: ["Created By {{name}}", "Angelegt von {{name}}"],
    daysInCurrentLevel: ["Days in current level", "Tage in aktuellem Härtegrad"],
    currentLevelDuedate: ["Due date for current level", "Fälligkeitsdatum für aktuellen Härtegrad"],
    currentLevelAssignedTo: ["Responsible for current level", "Für aktuellen Härtegrad verantwortlich"],
    currentDecider: ["Decision maker", "Entscheider*in"],
    measureConfigId: ["Process Type", "Prozesstyp"],
    currentGateTaskConfigId: ["Level", "Härtegrad"],
    [AttributeTitle.Description]: ["Description", "Beschreibung"],
    favorite: ["Favorite", "Favorit"],
    [AttributeTitle.GroupsWithAccess]: ["Groups with directly assigned read access", "Gruppen mit direkt zugewiesenem Lesezugriff"],
    [mergeCamelized(AttributeTitle.Description, "hint")]: [
        "You can describe your measure in more detail here if necessary. However, a very detailed documentation and/ or calculation should be attached as a document.",
        "Falls erforderlich, können Sie hier Ihre Maßnahme näher beschreiben. Eine ganz ausführliche Dokumentation und/ oder Berechnung sollte aber als Dokument angehängt werden.",
    ],
    [mergeCamelized(AttributeTitle.Description, "postcaputure", "hint")]: [
        "You can describe your Post-Capture in more detail here if necessary. However, a very detailed documentation and/ or calculation should be attached as a document.",
        "Falls erforderlich, können Sie hier Ihre Nacherfassung näher beschreiben. Eine ganz ausführliche Dokumentation und/ oder Berechnung sollte aber als Dokument angehängt werden.",
    ],
    processPulse: ["Process Pulse", "Prozess-Puls"],
    processPulseHint: [
        "The Process Pulse visually shows how many changes have been made to the process in the last few weeks. This visualizes which processes had more or less activity recently.",
        "Der Prozess-Puls zeigt visuell, wieviele Veränderungen es am Prozess in den letzten Wochen gab. So wird sichtbar, bei welchen Prozessen zuletzt mehr oder weniger Aktivität stattfand.",
    ],
    [MeasureFieldNames.AssignedEditors]: ["Editing users", "Bearbeitende Benutzer:innen"],
    [MeasureFieldNames.CommentLast]: ["Last comment", "Letzter Kommentar"],
    [MeasureFieldNames.CommentsCount]: ["Number of comments", "Anzahl Kommentare"],
    ...basicEffectFields,
    ...levelEffectFields,
    ...totalEffectFields,
    [IDEA_FIELD_PREFIX]: ideaFields,
};
