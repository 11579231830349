import { normalizeString } from "./normalize";

/**
 * Search for search text within input with respect to diacritics.
 * @param {string} input
 * @param {string} search
 * @returns
 */
export function searchWithDiacritics(input: string, search: string): boolean {
    const normalizedSearch = normalizeString(search);
    const normalizedInput = normalizeString(input);

    return normalizedInput.includes(normalizedSearch);
}
