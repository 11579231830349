import { useQuery } from "@tanstack/react-query";
import { TranslationDto, TranslationListDto } from "api-shared";
import i18next, { changeLanguage } from "i18next";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { takeEvery } from "redux-saga/effects";
import { apiGet } from "../lib/api";
import { Language, languages } from "../translations/main-translations";

export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
type ChangeLanguageAction = ReturnType<typeof changeLanguageAction>;

export const changeLanguageAction = (language: Language) => ({
    type: CHANGE_LANGUAGE,
    language,
});

export function* getTranslationsSaga() {
    yield takeEvery(CHANGE_LANGUAGE, ({ language }: ChangeLanguageAction) => {
        changeLanguage(language);
    });
}

export const TranslationQueryKeys = {
    default: ["translations"] as const,
};

export const useCustomTranslationsQuery = (enabled = true) => {
    const [translationsApplied, setTranslationsApplied] = useState(false);
    const { data, error } = useQuery({
        queryKey: TranslationQueryKeys.default,
        queryFn: ({ signal }) => apiGet<TranslationListDto>("translations", { signal }),
        enabled,
    });
    // Workaround for callbacks not being fired on saveQueryData anymore
    // https://tanstack.com/query/v4/docs/react/guides/migrating-to-react-query-4#onsuccess-is-no-longer-called-from-setquerydata
    useEffect(() => {
        if (data) {
            applyTranslations(data);
        }
        if (data !== undefined || error) {
            setTranslationsApplied(true);
        }
    }, [data, error]);
    return translationsApplied;
};

export const useLanguageChange = () => {
    const dispatch = useDispatch();
    return useCallback(
        (language: Language) => {
            dispatch({ type: CHANGE_LANGUAGE, language });
        },
        [dispatch],
    );
};

export function applyTranslations(translationsResponse: TranslationDto[]) {
    // Unlike lodash.set, this does not create arrays for numeric keys
    function set(obj: Record<string, any>, key: string, value: string) {
        const [current, ...rest] = key.split(".");
        obj[current] = obj[current] || {};
        if (rest.length >= 1) {
            set(obj[current], rest.join("."), value);
            return;
        }
        obj[current] = value;
        return obj;
    }
    languages.forEach((lang) => {
        // Create an object of nested translations
        const nestedData = {};
        translationsResponse.forEach((t) => set(nestedData, t.key, t[lang]));
        // deep and overwrite need to be true to override nested translations
        i18next.addResourceBundle(lang, "translation", nestedData, true, true);
    });
}
