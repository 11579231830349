import { Button, ButtonProps } from "@mui/material";
import { TFunction } from "i18next";
import React from "react";
import DangerButton from "../DangerButton";

export interface IDialogButtonProps extends Omit<ButtonProps, "translate"> {
    name: string;
    translate?: TFunction;
    disabled?: boolean;
    danger?: boolean;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    onClose?: React.MouseEventHandler<HTMLButtonElement>;
    testId?: string;
}

const DialogButton = ({ disabled, onClick, name, translate, onClose, danger, testId, ...other }: IDialogButtonProps) => {
    const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (typeof onClick === "function") {
            onClick(e);
        }
        if (typeof onClose === "function") {
            onClose(e);
        }
    };

    const Component = danger ? DangerButton : Button;
    return (
        <Component disabled={disabled} onClick={handleClick} {...other} data-testid={testId}>
            {typeof translate === "function" ? translate(name) : name}
        </Component>
    );
};
export default DialogButton;
