import { Action, combineReducers } from "redux";
import { fieldDataReducer } from "../domain/field-data";
import { measureReducer } from "../domain/measure/detail";
import { SIGN_OUT } from "../lib/authentication-saga";
import { BOOTSTRAP_FAILED, BOOTSTRAP_STARTED, BOOTSTRAP_SUCCEEDED } from "../lib/bootstrap";
import { ActionType } from "../types/helpers";
import { notificationReducer } from "./notifications";

const reducers = {
    fieldData: fieldDataReducer,
    isFetching: isFetchingReducer,
    measures: measureReducer,
    notifications: notificationReducer,
};

export const createRootReducer = () => {
    const appReducer = combineReducers(reducers);
    return (state: ReturnType<typeof appReducer> | undefined, action: ActionType<typeof SIGN_OUT>) => {
        return appReducer(action.type === SIGN_OUT ? undefined : state, action);
    };
};

function isFetchingReducer(
    state = {
        bootstrap: true,
        search: false,
    },
    action: Action<string>, // Don't try too hard, will be replaced by react-query's isFetching soon
) {
    switch (action.type) {
        case BOOTSTRAP_FAILED:
        case BOOTSTRAP_SUCCEEDED:
            return { ...state, bootstrap: false };
        case BOOTSTRAP_STARTED:
            return { ...state, bootstrap: true };
        default:
            return state;
    }
}
