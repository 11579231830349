import { ErrorConstantKeys } from "api-shared";

const badRequestErrors: ErrorConstantKeys[] = [
    ErrorConstantKeys.VDERROR_BAD_REQUEST,
    ErrorConstantKeys.VDERROR_BAD_CONTENT,
    ErrorConstantKeys.VDERROR_BAD_MEASURE,
    ErrorConstantKeys.VDERROR_BAD_FILTER_DEFINITION,
    ErrorConstantKeys.VDERROR_BAD_GATETASK,
    ErrorConstantKeys.VDERROR_BAD_COMPLETED_GATETASK,
    ErrorConstantKeys.VDERROR_BAD_USER,
    ErrorConstantKeys.VDERROR_BAD_USER_STATUS,
    ErrorConstantKeys.VDERROR_BAD_PASSWORD,
    ErrorConstantKeys.VDERROR_BAD_CURRENCY_FORMAT,
    ErrorConstantKeys.VDERROR_BAD_TIME_RANGE,
    ErrorConstantKeys.VDERROR_BAD_ORDER,
    ErrorConstantKeys.VDERROR_BAD_PROPERTY,
    ErrorConstantKeys.VDERROR_BAD_EMAIL,
    ErrorConstantKeys.VDERROR_BAD_COMMENT_LENGTH,
    ErrorConstantKeys.VDERROR_BAD_ACCEPT_CONTACT,
    ErrorConstantKeys.VDERROR_BAD_THUMBNAIL,
    ErrorConstantKeys.VDERROR_BAD_MEASURE_IDS,
    ErrorConstantKeys.VDERROR_BAD_PERMISSIONS,
    ErrorConstantKeys.VDERROR_BAD_ADMIN_REQUEST_TYPE,
    ErrorConstantKeys.VDERROR_BAD_COST_LEVER_SEARCH,
    ErrorConstantKeys.VDERROR_USER_PROFILE_ERROR,
    ErrorConstantKeys.VDERROR_BAD_REQUEST_NO_MORE_USERS,
];

/**
 * Check if the given error key is a bad request error.
 *
 * @param {ErrorConstantKeys} error
 * @returns {boolean}
 */
export const isBadRequestError = (error: ErrorConstantKeys): boolean => badRequestErrors.includes(error);

export const errorTranslations = {
    // use nested syntax to avoid collisions with other translation keys
    error: {
        [ErrorConstantKeys.VDERROR_UNKNOWN]: ["Unknown Error (01)", "Unbekannter Fehler (01)"],
        [ErrorConstantKeys.VDERROR_UNAUTHORIZED_TOKEN]: ["Link/Token invalid", "Link/Token ungültig"],
        [ErrorConstantKeys.VDERROR_UNAUTHORIZED_RESET_TOKEN]: [
            "Password reset link is expired",
            "Der Passwort-Zurücksetzen-Link ist abgelaufen",
        ],
        [ErrorConstantKeys.VDERROR_UNAUTHORIZED_SUPER_ADMIN]: ["You are not allowed to be here (02)", "Hier gibt es nichts zu sehen (02)"],
        [ErrorConstantKeys.VDERROR_NOT_FOUND_MEASURE]: ["Measure not found (03)", "Maßnahme nicht gefunden (03)"],
        [ErrorConstantKeys.VDERROR_NOT_FOUND_COMMENT]: ["Comment not found (04)", "Kommentar nicht gefunden (04)"],
        [ErrorConstantKeys.VDERROR_NOT_FOUND_COMPANY]: ["Company not found (05)", "Gesellschaft nicht gefunden (05)"],
        [ErrorConstantKeys.VDERROR_NOT_FOUND_FILTER]: ["Filter not found (06)", "Filter nicht gefunden (06)"],
        [ErrorConstantKeys.VDERROR_NOT_FOUND_GATETASK]: ["Level not found (07)", "Härtegrad nicht gefunden (07)"],
        [ErrorConstantKeys.VDERROR_NOT_FOUND_CLIENT]: ["Organization not found (08)", "Unternehmen nicht gefunden (08)"],
        [ErrorConstantKeys.VDERROR_NOT_FOUND_USER]: ["User not found (09)", "Benutzer nicht gefunden (09)"],
        [ErrorConstantKeys.VDERROR_NOT_FOUND_SUBTASK]: ["Activity not found (10)", "Aktivität nicht gefunden (10)"],
        [ErrorConstantKeys.VDERROR_NOT_FOUND_GATETASK_FOR_MEASURE]: [
            "No levels for this measure found (11)",
            "Keine Härtegrade für diese Maßnahme gefunden (11)",
        ],
        [ErrorConstantKeys.VDERROR_NOT_FOUND_ADDRESS]: ["Location not found (12)", "Standort nicht gefunden (12)"],
        [ErrorConstantKeys.VDERROR_NOT_FOUND_SUPPLIER]: ["Supplier not found (13)", "Lieferant nicht gefunden (13)"],
        [ErrorConstantKeys.VDERROR_NOT_FOUND_MEASURE_USER]: ["Permission not found (14)", "Berechtigung nicht gefunden (14)"],
        [ErrorConstantKeys.VDERROR_NOT_FOUND_MEASURE_FOR_TOKEN]: ["Link invalid (16)", "Link ungültig (16)"],
        [ErrorConstantKeys.VDERROR_NOT_FOUND_FILE]: ["File not found (40)", "Dokument nicht gefunden (40)"],
        [ErrorConstantKeys.VDERROR_NOT_FOUND_COST_LEVER]: ["Method not found (41)", "Methode nicht gefunden (41)"],
        [ErrorConstantKeys.VDERROR_NOT_FOUND_ATTRIBUTE]: ["Attribute not found (44)", "Attribut nicht gefunden (44)"],
        [ErrorConstantKeys.VDERROR_NOT_FOUND_FEED_ENTRY]: ["Feed entry not found (45)", "Feed-Eintrag nicht gefunden (45)"],
        [ErrorConstantKeys.VDERROR_FORBIDDEN_ACCESS]: ["Access not allowed (18)", "Zugriff nicht erlaubt (18)"],
        [ErrorConstantKeys.VDERROR_FORBIDDEN_ACCESS_MODIFY_COMMENT]: ["Action not allowed (19)", "Vorgang nicht erlaubt (19)"],
        [ErrorConstantKeys.VDERROR_FORBIDDEN_MODIFY_SEARCH]: ["Action not allowed (20)", "Vorgang nicht erlaubt (20)"],
        [ErrorConstantKeys.VDERROR_FORBIDDEN_ACCESS_AUTHUSER]: ["Access not allowed (21)", "Zugriff nicht erlaubt (21)"],
        [ErrorConstantKeys.VDERROR_FORBIDDEN_DELETION_FILTER]: ["Action not allowed (22)", "Vorgang nicht erlaubt (22)"],
        [ErrorConstantKeys.VDERROR_FORBIDDEN_MODIFY_FILTER]: ["Action not allowed (23)", "Vorgang nicht erlaubt (23)"],
        [ErrorConstantKeys.VDERROR_FORBIDDEN_ACCESS_SET_USER]: [
            "Permission to be responsible for a process is missing",
            "Berechtigung zur Verantwortung für einen Prozess fehlt",
        ],
        [ErrorConstantKeys.VDERROR_BAD_REQUEST_VALUESTREAM_PERMISSION_MISSING]: [
            "Permission to use the Valuestream is missing",
            "Berechtigung zur Nutzung des Valuestreams fehlt",
        ],
        [ErrorConstantKeys.VDERROR_FORBIDDEN_ACCESS_ADD_USER]: ["Action not allowed (25)", "Vorgang nicht erlaubt (25)"],
        [ErrorConstantKeys.VDERROR_FORBIDDEN_ACCESS_TO_ASSIGN]: ["Action not allowed (26)", "Vorgang nicht erlaubt (26)"],
        [ErrorConstantKeys.VDERROR_FORBIDDEN_ACCESS_MODIFY_PERMISSIONS]: ["Action not allowed (27)", "Vorgang nicht erlaubt (27)"],
        [ErrorConstantKeys.VDERROR_FORBIDDEN_ACCESS_MODIFY_NOTIFICATIONS]: [
            "Only the measure owner is allowed to modify notifications of other users",
            "Nur der Maßnahmenverantwortlich darf Benachrichtigungen anderer Benutzer ändern",
        ],
        [ErrorConstantKeys.VDERROR_FORBIDDEN_ACCESS_RESET_PASSWORD]: ["Action not allowed (28)", "Vorgang nicht erlaubt (28)"],
        [ErrorConstantKeys.VDERROR_FORBIDDEN_ACCESS_ADMIN]: [
            "Access to admin section not allowed (29)",
            "Zugriff auf Admin-Bereich nicht erlaubt (29)",
        ],
        [ErrorConstantKeys.VDERROR_FORBIDDEN_ACCESS_CLIENT_ID]: ["Action not allowed (42)", "Vorgang nicht erlaubt (42)"],
        [ErrorConstantKeys.VDERROR_CONFLICT_EMAIL]: ["Email already in use (30)", "Email wird schon verwendet (30)"],
        [ErrorConstantKeys.VDERROR_CONFLICT_DOMAINS_FOUND]: [
            "Domain(s) already in use: {{domains}} (31)",
            "Domain(s) schon verwendet: {{domains}} (31)",
        ],
        [ErrorConstantKeys.VDERROR_CONFLICT_FILTER]: [
            "Filter name '{{name}}' already in use (32)",
            "Filtername '{{name}}' wird bereits verwendet (32)",
        ],
        [ErrorConstantKeys.VDERROR_CONFLICT_PREDECESSOR_GATETASK]: [
            "Preceding level not completed (33)",
            "Vorheriger Härtegrad noch nicht abgeschlossen (33)",
        ],
        [ErrorConstantKeys.VDERROR_CONFLICT_EFFECT_CATEGORY_EXIST]: [
            "Effect category already added (34)",
            "Effektkategorie ist bereits hinzugefügt (34)",
        ],
        [ErrorConstantKeys.VDERROR_CONFLICT_MEASURE_USER_EXISTS]: ["User already added (35)", "Benutzer bereits hinzugefügt (35)"],
        [ErrorConstantKeys.VDERROR_CONFLICT_GATETASK]: ["Level not filled in correctly (36)", "Härtegrad nicht korrekt ausgefüllt (36)"],
        [ErrorConstantKeys.VDERROR_CONFLICT_REMOVE_COMPANY_IN_USE]: ["Company still in use (37)", "Gesellschaft wird noch verwendet (37)"],
        [ErrorConstantKeys.VDERROR_CONFLICT_COMPANY_MIN_ADDRESS]: [
            "Company needs at least one remaining address (38)",
            "Die Gesellschaft braucht mindestens einen Standort (38)",
        ],
        [ErrorConstantKeys.VDERROR_BAD_REQUEST]: ["Invalid request (39)", "Ungültige Anfrage (39)"],
        [ErrorConstantKeys.VDERROR_BAD_AUTHENTICATION]: ["Login failed", "Login fehlgeschlagen"],
        [ErrorConstantKeys.VDERROR_BAD_FILTER_BY_PERSON]: [
            "You are no longer permitted to use the selected filter. (43)",
            "Sie sind nicht länger berechtigt den ausgewählten Filter anzuwenden. (43)",
        ],
        [ErrorConstantKeys.VDERROR_UNAUTHORIZED_PASSWORD]: ["Old password is wrong", "Bisheriges Passwort falsch"],
        [ErrorConstantKeys.VDERROR_REMOVE_FAILED]: ["Deletion failed", "Entfernen fehlgeschlagen"],
        [ErrorConstantKeys.VDERROR_DO_NOT_BITE_THE_HAND_THAT_FEEDS_YOU]: ["Do not bite the hand you feed", "Säge nicht am eigenen Ast"],
        [ErrorConstantKeys.VDERROR_DO_NOT_DELETE_SUPER_ADMIN]: [
            "Do not delete our Super Admin!",
            "Du darfst den Super Admin nicht löschen!",
        ],
        [ErrorConstantKeys.VDERROR_INVALID_SORT_COLUMN]: [
            "Order by column '{{column}}' is not possible: ",
            "Sortierung nach Spalte '{{column}}' nicht möglich: ",
        ],
        [ErrorConstantKeys.VDERROR_FORBIDDEN_ACCESS_PERMISSION_REQUIRED]: [
            "A permission is required for this action: ",
            "Eine zusätzliche Berechtigung wird dafür benötigt: ",
        ],
        [ErrorConstantKeys.VDERROR_INTERNAL_SERVER]: ["Internal server error", "Serverfehler"],
        [ErrorConstantKeys.VDERROR_INTERNAL_MEASURE_NOT_CREATED]: ["Measure not created", "Maßnahme nicht erstellt"],
        [ErrorConstantKeys.VDERROR_NOT_FOUND_MEASURE_CONFIG]: ["Measure configuration not found", "Maßnahmenkonfiguration nicht gefunden"],
        [ErrorConstantKeys.VDERROR_DOMAIN_IS_IN_USE]: ["Domain is still in use.", "Die Domain ist noch in Nutzung."],
        [ErrorConstantKeys.VDERROR_BAD_REQUEST_DOMAIN_ALREADY_EXISTS]: ["Domain already exists.", "Die Domain existiert bereits."],
        [ErrorConstantKeys.VDERROR_CONFLICT_CURRENCY_ALREADY_EXISTS]: [
            "A currency with that name/code already exists.",
            "Eine Währung mit diesem Namen/Code existiert bereits.",
        ],
        [ErrorConstantKeys.VDERROR_NOT_FOUND_IDEA]: ["Opportunity not found", "Opportunität nicht gefunden"],
        [ErrorConstantKeys.VDERROR_NOT_FOUND_PERMISSION]: ["Permission not found", "Berechtigung nicht gefunden"],
        [ErrorConstantKeys.VDERROR_MEASURE_MENTIONED_USER_REMOVED]: [
            "Mentioned user is not part of this measure anymore.",
            "Der erwähnte Benutzer ist nicht mehr Teil dieser Maßnahme.",
        ],
        [ErrorConstantKeys.VDERROR_BAD_REQUEST_NO_PRIVATE_MEASURE_PERMISSION]: [
            "Some widgets won't show data because you don't have the permission to see private processes.",
            "Einige Widgets zeigen keine Daten an, weil Sie nicht die Erlaubnis haben, private Prozesse zu sehen.",
        ],
        [ErrorConstantKeys.VDERROR_BAD_AUTHENTICATION_NO_MORE_USERS]: [
            "You cannot log in because {{clientName}} already has all user seats in use. Please contact your Valuedesk representative or contact support@valuedesk.de.",
            "Sie können sich nicht einloggen, da {{clientName}}  bereits alle Benutzerzugänge verwendet. Bitte wenden Sie sich an Ihren Valuedesk-Verantwortlichen oder bei support@valuedesk.de.",
        ],
        [ErrorConstantKeys.VDERROR_INCORRECT_TFA_TOKEN]: ["Invalid code", "Ungültiger Code"],
        [ErrorConstantKeys.VDERROR_RATE_LIMIT_REACHED]: [
            "Rate limit reached. Please try again in a few seconds.",
            "Maximale Zugriffe erreicht. Bitte versuchen Sie es in ein paar Sekunden erneut.",
        ],
        [ErrorConstantKeys.VDERROR_BAD_REQUEST_TREE_NODE_IN_USE]: [
            "Tree node could not be deleted because it is used in a process or an effect category.",
            "Baumknoten konnte nicht gelöscht werden, weil er in einem Prozess oder einer Effektkategorie verwendet wird.",
        ],
        [ErrorConstantKeys.VDERROR_BAD_REQUEST_TREE_NODE_PARENT_ATTRIBUTE_MISMATCH]: [
            "The selected parent node is invalid because it has a different attribute type.",
            "Der ausgewählte Elternknoten ist ungültig, weil er einen anderen Attribut-Typ hat.",
        ],
        [ErrorConstantKeys.VDERROR_BAD_REQUEST_NEW_MEASURE_ASSIGNEE_NEEDED]: [
            "The measure needs a new assignee before it can be updated.",
            "Die Maßnahme benötigt einen neuen Verantwortlichen bevor sie aktualisiert werden kann.",
        ],
        [ErrorConstantKeys.VDERROR_FORBIDDEN_ACCESS_PROCESS]: [
            "Invalid request: Missing permission for process",
            "Ungültige Anfrage: Keine Berechtigung für den Prozess",
        ],
        [ErrorConstantKeys.VDERROR_BAD_FILTER_DEFINITION_PROBABILITY]: [
            "A probability value was not provided",
            "Es wurde kein Wahrscheinlichkeitswert angegeben",
        ],
        [ErrorConstantKeys.VDERROR_FORBIDDEN_NO_PERMISSION]: [
            "Invalid request: The user is missing the necessary permission.",
            "Ungültige Anfrage: Der Benutzer hat nicht die erforderliche Berechtigung.",
        ],
        [ErrorConstantKeys.VDERROR_CONFLICT_LEVEL_LOCKED]: [
            "Level is locked and can not be changed.",
            "Härtegrad ist gesperrt und kann nicht verändert werden.",
        ],
        [ErrorConstantKeys.VDERROR_CONFLICT_EFFECT_CATEGORIES_LOCKED]: [
            "A calculation level is locked. Effect categories can not be changed.",
            "Ein Berechnungshärtegrad ist gesperrt. Effektkategorien können nicht verändert werden.",
        ],
        [ErrorConstantKeys.VDERROR_NOT_FOUND_ACL_USER_ENTRY]: [
            "User permission entry not found.",
            "Benutzerberechtigungseintrag nicht gefunden.",
        ],
        [ErrorConstantKeys.VDERROR_NOT_FOUND_ACL_GROUP_ENTRY]: [
            "Group permission entry not found.",
            "Gruppenberechtigungseintrag nicht gefunden.",
        ],
        [ErrorConstantKeys.VDERROR_NOT_FOUND_ATTRIBUTE_CATEGORY]: [
            "Attribute category not found.",
            "Attribut-Kategorie konnte nicht gefunden werden.",
        ],
        [ErrorConstantKeys.VDERROR_ATTRIBUTE_CATEGORY_IN_USE]: [
            "Attribute category is in use and can't be deleted.",
            "Attribut-Kategorie ist in Verwendung und kann nicht gelöscht werden.",
        ],
        [ErrorConstantKeys.VDERROR_INVALID_FILTER_DEFINITION]: ["Invalid filter", "Ungültiger Filter"],
        [ErrorConstantKeys.VDERROR_CONFLICT_DECISION_ALREADY_COMPLETED]: [
            "The decision level has already been completed and cannot be changed.",
            "Der Entscheidungshärtegrad wurde bereits abgeschlossen und kann nicht mehr verändert werden.",
        ],
        [ErrorConstantKeys.VDERROR_BAD_DATERANGE]: ["Date range is invalid.", "Datumsbereich ist ungültig."],
        [ErrorConstantKeys.VDERROR_CONFLICT_USER_INVITE_STATUS_MISMATCH]: [
            "Could not re-invite user because they have already been activated, deactivated or deleted.",
            "Benutzer:in konnte nicht erneut eingeladen werden, weil er/sie zwischenzeitlich bereits aktiviert, deaktiviert oder gelöscht wurde.",
        ],

        [ErrorConstantKeys.VDERROR_NOT_FOUND_EFFECT_CATEGORY]: ["Effect category not found.", "Effektkategorie nicht gefunden."],
        [ErrorConstantKeys.VDERROR_FORBIDDEN_CREATE_MEASURE]: [
            "User does not have permission to create measures",
            "Benutzer:in hat keine Berechtigung Prozesse zu erstellen",
        ],
        [ErrorConstantKeys.VDERROR_BAD_REQUEST_READONLY_FEATURE_FLAG_CHANGED]: [
            "Not possible to set or remove a readonly feature flag.",
            "Es ist nicht möglich ein nicht änderbares Feature Flag zu setzen oder zu entfernen.",
        ],
        [ErrorConstantKeys.VDERROR_BAD_REQUEST_READONLY_RECURRING_EFFECT_DELETED]: [
            "Not possible to manually delete automatically generated recurring effects.",
            "Es ist nicht möglich automatisch generierte wiederkehrende Effekte manuell zu entfernen.",
        ],
    },
};
