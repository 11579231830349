import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import {
    Card,
    CardActionArea,
    FormControl,
    Grid,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Divider as MuiDivider,
    Select,
    SelectChangeEvent,
    Stack,
    Tab,
    Tabs,
    Typography,
    selectClasses,
    styled,
} from "@mui/material";
import { FeatureFlags, LATEST_PREFIX, SubTaskDto, SubTaskPriority, UserDto, zShortText } from "api-shared";
import { TFunction } from "i18next";
import { debounce } from "lodash";
import moment, { Moment } from "moment";
import momentTimezone from "moment-timezone";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { useLocation } from "react-router-dom";
import { useClientHasFeature } from "../../domain/client";
import { useMeasureConfig } from "../../domain/measure-config";
import { useMeasureEditorsQuery } from "../../domain/measure/permission";
import { SubTaskUpdateChanges } from "../../domain/subtasks";
import { useAllUsers } from "../../domain/users";
import useDialog from "../../hooks/useDialog";
import { translationKeys } from "../../translations/main-translations";
import { useMeasureContext } from "../../view/MeasureContext";
import InfoIcon from "../icons/InfoIcon";
import EditableText from "../input/EditableText";
import DatePicker from "../input/date/DatePicker";
import MarkdownEditorUserMentionsMeasure from "../markdowneditor/MarkdownEditorUserMentionsMeasure";
import SingleUser from "../user/SingleUser";
import MeasureLink from "./MeasureLink";
import SubtaskEffortChart from "./SubtaskEffortChart";
import SubtaskEffortDialog from "./SubtaskEffortDialog";
import SubtaskLabel from "./SubtaskLabel";
import SubtaskListItemPriorityIcon from "./SubtaskListItemPriorityIcon";
import SubtaskProgress from "./SubtaskProgress";

const SelectOptionIcon = styled(ListItemIcon)(({ theme }) => ({
    display: "inline-flex",
    alignItems: "center",
    paddingRight: theme.spacing(1),
    minWidth: theme.spacing(1),
}));

const SelectOptionLabel = styled(ListItemText)(({ theme }) => ({
    ...theme.typography.body1,
    fontSize: theme.typography.fontSize,
    marginTop: theme.spacing(0.75),
}));

const PrioritySelect = styled(Select)(({ theme }) => ({
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
    padding: 0,
    [`.${selectClasses.select}`]: {
        display: "inline-flex", // avoid wrapping of icon from text
    },
}));

const Divider = styled(MuiDivider)(({ theme }) => ({
    marginLeft: theme.spacing(-3),
    marginRight: theme.spacing(-3),
}));

const LevelSelect = styled(Select)(({ theme }) => ({
    "label + &": {
        marginTop: theme.spacing(-1),
    },
}));

const ListItem = styled(ListItemText)(({ theme }) => ({
    fontSize: theme.typography.fontSize,
}));

export interface ISubtaskDetailContentProps {
    task: SubTaskDto;
    translate: TFunction;
    disabled: boolean;
    updateModifiedTitle: (newTitle: string | null) => void;
    onTitleSave: (title: string) => void;
    onAbortTitle: () => void;
    updateTask: (changes: SubTaskUpdateChanges) => void;
    users: UserDto[];
    modifiedTitle: string | null;
    updateComment: (commentId: number, comment: string) => void;
    deleteComment: (commentId: number) => void;
    hideProgress?: boolean;
    hideMeasureInfo?: boolean;
}

const SubtaskDetailContent = ({
    task,
    translate,
    disabled,
    updateModifiedTitle,
    onTitleSave,
    onAbortTitle,
    updateTask,
    users,
    modifiedTitle,
    updateComment,
    deleteComment,
    hideProgress,
    hideMeasureInfo,
}: ISubtaskDetailContentProps) => {
    const taskTitle = modifiedTitle ?? task.title;
    const [isTitleTooLong, setIsTitleTooLong] = useState(false);
    const [selectedTab, setSelectedTab] = useState(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const updateTitleDebounced = useCallback(
        debounce((newTitle: string | null) => {
            setIsTitleTooLong(!zShortText.safeParse(newTitle).success);
            return newTitle ?? updateModifiedTitle(newTitle);
        }, 500),
        [updateModifiedTitle],
    );

    const orderedPriorities = [SubTaskPriority.PRIO_HIGH, SubTaskPriority.PRIO_MEDIUM, SubTaskPriority.PRIO_LOW];

    const currentMeasure = useMeasureContext();
    const allUsers = useAllUsers();
    const editorsQuery = useMeasureEditorsQuery(currentMeasure.id);
    const measureConfig = useMeasureConfig(currentMeasure);

    const locationState = useLocation().state;

    // TODO Use one source instead of two (currentMeasure - activity tab, task.measure - activity view)
    // TODO https://valued.atlassian.net/browse/DEV-1110
    let startDate = task.effectivenessStart;
    const measureLatestStartDate = currentMeasure.effects[LATEST_PREFIX]?.startDate;
    if (startDate === undefined && measureLatestStartDate != null) {
        startDate = moment({ ...measureLatestStartDate, day: 1 })
            .toDate()
            .toJSON();
    }

    const infoMessage =
        startDate && moment(task.duedate).isSameOrAfter(startDate, "days")
            ? translate(translationKeys.VDLANG_DUEDATE_AFTER_START_WARNING)
            : "";

    const handleLevelChange = (e: SelectChangeEvent<unknown>) => {
        const value = +(e.target.value as string);
        updateTask({ gateTaskId: value === 0 ? null : value });
    };
    const handleDateChange = (duedate: Moment | null) => updateTask({ duedate });

    const shouldCloseOnChange = (date: Moment | null) => (startDate ? !moment(date).isSameOrAfter(startDate, "days") : true);

    const effortDialog = useDialog();
    const hasEffortEstimationFeature = useClientHasFeature(FeatureFlags.FEATURE_EFFORT_ESTIMATION);
    const orderedGateTaskConfigs = measureConfig?.gateTaskConfigs.toSorted((configA, configB) => configA.order - configB.order) ?? [];
    const levelOptions = orderedGateTaskConfigs.map((config) => {
        const gateTask = currentMeasure.gateTasks.find(({ gateTaskConfigId }) => gateTaskConfigId === config.id);
        if (gateTask === undefined) {
            return null;
        }
        return (
            <MenuItem value={gateTask.id} key={gateTask.id}>
                <ListItem disableTypography primary={translate(config.name)} />
            </MenuItem>
        );
    });

    const taskRemindAt = task.remindAt != null ? new Date(task.remindAt) : null;

    const editors = allUsers.filter((user) => editorsQuery.data?.combinedUserIds.includes(user.id));

    return (
        <Stack spacing={5}>
            <Stack spacing={3} direction="row">
                {/* Apply negative margin so text is aligned with other content and hover effect can be large the element itself */}
                <Typography variant="body1" component="div" flexGrow={1} minWidth={0} mx={-1.5}>
                    <EditableText
                        variant="body1"
                        defaultIsEditing={modifiedTitle != null || locationState?.setTaskTitle}
                        text={taskTitle ?? ""}
                        disabled={disabled}
                        onTextSave={onTitleSave}
                        onTextChange={updateTitleDebounced}
                        onEditAbort={onAbortTitle}
                        placeholder={translate(translationKeys.VDLANG_ACTIVITIES_NEW_ACTIVITY_TEXTFIELD_PLACEHOLDER)}
                        errorText={isTitleTooLong ? translate(translationKeys.VDLANG_ACTIVITIES_TITLE_LENGTH_ERROR) : null}
                    />
                </Typography>

                <PrioritySelect
                    disabled={disabled}
                    disableUnderline
                    variant="standard"
                    value={task.priority}
                    IconComponent={KeyboardArrowDownRoundedIcon}
                    sx={{ fontSize: (theme) => theme.typography.fontSize }}
                    onChange={(e) => updateTask({ priority: e.target.value as SubTaskPriority })}
                >
                    {orderedPriorities.map((prio) => (
                        <MenuItem value={prio} key={`liprio_${prio}`}>
                            <SelectOptionIcon>
                                <SubtaskListItemPriorityIcon priority={prio} translate={translate} />
                            </SelectOptionIcon>
                            <SelectOptionLabel disableTypography>{translate(`subtask_priority.${prio}`)}</SelectOptionLabel>
                        </MenuItem>
                    ))}
                </PrioritySelect>
            </Stack>
            {hasEffortEstimationFeature && (
                <Grid item xs={12}>
                    {/* Using card (elevation = 0) here to profit from layouting, even 
                    though it is more or less a button with custom layout */}
                    <Card variant="elevation" elevation={0}>
                        <CardActionArea onClick={effortDialog.open} disabled={disabled}>
                            <SubtaskLabel>{translate(translationKeys.VDLANG_ACTIVITY_EFFORT_ESTIMATION_TITLE)}</SubtaskLabel>
                            <SubtaskEffortChart activity={task} disabled={disabled} />
                        </CardActionArea>
                    </Card>
                    {effortDialog.isOpen && (
                        <SubtaskEffortDialog task={task} updateTask={updateTask} open={effortDialog.isOpen} onClose={effortDialog.close} />
                    )}
                </Grid>
            )}
            {/* Wrap with div to avoid Grid margin being overwritten by parent Stack */}
            <div>
                <Grid container columnSpacing={5}>
                    {!hideMeasureInfo && task.measure != null ? (
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <SubtaskLabel>{translate(translationKeys.VDLANG_PROCESS)}</SubtaskLabel>
                                <MeasureLink measureId={task.measure.id} clientIid={task.measure.clientIid} title={task.measure.title} />
                            </FormControl>
                        </Grid>
                    ) : null}
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <SubtaskLabel>{translate(translationKeys.VDLANG_GATETASK)}</SubtaskLabel>
                            <LevelSelect
                                disabled={disabled}
                                variant="standard"
                                disableUnderline
                                value={task.gateTaskId ?? 0}
                                onChange={handleLevelChange}
                                IconComponent={KeyboardArrowDownRoundedIcon}
                                size="small"
                            >
                                <MenuItem value={0}>
                                    <ListItem
                                        disableTypography
                                        primary={translate(translationKeys.VDLANG_ACTIVITIES_GATE_TASK_NOT_ASSIGNED)}
                                    />
                                </MenuItem>
                                {levelOptions}
                            </LevelSelect>
                        </FormControl>
                    </Grid>
                </Grid>
            </div>
            {/* Wrap with div to avoid Grid margin being overwritten by parent Stack */}
            <div>
                <Grid container columnSpacing={5}>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <SubtaskLabel>
                                {`${translate(translationKeys.VDLANG_RESPONSIBLE)} `}
                                <InfoIcon title={translate(translationKeys.VDLANG_ACTIVITIES_ASSIGNED_TO_HINT)} />
                            </SubtaskLabel>
                            <SingleUser
                                users={editors}
                                user={users.find((user) => user.id === task.assignedToId)}
                                disabled={disabled}
                                translate={translate}
                                closeOnSelect
                                update={(userId: number | null) => updateTask({ assignedToId: userId })}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <SubtaskLabel>{translate(translationKeys.VDLANG_DUE_DATE)}</SubtaskLabel>
                            <DatePicker
                                disabled={disabled}
                                date={task.duedate ? momentTimezone.utc(task.duedate) : null}
                                onDateChange={handleDateChange}
                                remindAt={task.assignedToId ? taskRemindAt : undefined} // only pass if user is assigned
                                onReminderChange={(remindAt) => updateTask({ remindAt })}
                                shouldCloseOnChange={shouldCloseOnChange}
                                infoMessage={infoMessage}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </div>
            <FormControl fullWidth>
                <SubtaskLabel>{translate("description")}</SubtaskLabel>
                <MarkdownEditorUserMentionsMeasure
                    disabled={disabled}
                    value={task.description ?? ""}
                    updateValue={(newValue: string) => updateTask({ description: newValue })}
                    placeholder={translate("Enter a description")}
                />
            </FormControl>
            {!hideProgress && selectedTab !== undefined && (
                <div>
                    <Tabs value={selectedTab ? 1 : 0} onChange={(e, v) => setSelectedTab(v === 1)}>
                        <Tab label={`${translate(translationKeys.VDLANG_COMMENTS)} (${task.comments?.length ?? 0})`} />
                        <Tab label={translate("history")} />
                    </Tabs>
                    <Divider />
                    <SubtaskProgress
                        translate={translate}
                        users={users}
                        selectedTab={selectedTab}
                        comments={task.comments}
                        updateComment={updateComment}
                        deleteComment={deleteComment}
                        measureId={task.measureId}
                        subTaskId={task.id}
                        disabled={disabled}
                    />
                </div>
            )}
        </Stack>
    );
};

SubtaskDetailContent.propTypes = {
    task: PropTypes.object.isRequired,
    translate: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    updateModifiedTitle: PropTypes.func.isRequired,
    onTitleSave: PropTypes.func.isRequired,
    onAbortTitle: PropTypes.func.isRequired,
    updateTask: PropTypes.func.isRequired,
    users: PropTypes.arrayOf(PropTypes.object).isRequired,
    modifiedTitle: PropTypes.string,
    updateComment: PropTypes.func,
    deleteComment: PropTypes.func,
    hideProgress: PropTypes.bool,
};

export default SubtaskDetailContent;
