const lowerCaseNonDecomposableUnicodeChars = [
    {
        replacementLetter: " ",
        specialCharRegex: /\u00A0/g,
    },
    {
        replacementLetter: "0",
        specialCharRegex: /\u07C0/g,
    },
    {
        replacementLetter: "a",
        specialCharRegex: /[\u0061\u24d0\uff41\u2c65\u0250]/g,
    },
    {
        replacementLetter: "aa",
        specialCharRegex: /\ua733/g,
    },
    {
        replacementLetter: "ae",
        specialCharRegex: /\u00e6/g,
    },
    {
        replacementLetter: "ao",
        specialCharRegex: /\ua735/g,
    },
    {
        replacementLetter: "au",
        specialCharRegex: /\ua737/g,
    },
    {
        replacementLetter: "av",
        specialCharRegex: /\ua739\ua73b/g,
    },
    {
        replacementLetter: "ay",
        specialCharRegex: /\ua73d/g,
    },
    {
        replacementLetter: "b",
        specialCharRegex: /[\u0062\u24d1\uff42\u0180\u0183\u0253]/g,
    },
    {
        replacementLetter: "c",
        specialCharRegex: /[\u0063\u24d2\uff43\u0188\u023c\ua73f\u2184]/g,
    },
    {
        replacementLetter: "d",
        specialCharRegex: /[\u0064\u24d3\uff44\u0111\u018c\u0256\u0257\ua77a]/g,
    },
    {
        replacementLetter: "dh",
        specialCharRegex: /\u00f0/g,
    },
    {
        replacementLetter: "e",
        specialCharRegex: /[\u0065\u24d4\uff45\u0247\u025b\u01dd]/g,
    },
    {
        replacementLetter: "f",
        specialCharRegex: /[\u0066\u24d5\uff46\u0192\ua77c]/g,
    },
    {
        replacementLetter: "",
        specialCharRegex: /[\u0067\u24d6\uff47\u01e5\u0260\ua7a1\u1d79\ua77f]/g,
    },
    {
        replacementLetter: "h",
        specialCharRegex: /[\u0068\u24d7\uff48\u0127\u2c68\u2c76\u0265]/g,
    },
    {
        replacementLetter: "hv",
        specialCharRegex: /\u0195/g,
    },
    {
        replacementLetter: "i",
        specialCharRegex: /[\u0069\u24d8\uff49\u0268\u0131]/g,
    },
    {
        replacementLetter: "j",
        specialCharRegex: /[\u006a\u24d9\uff4a\u0249]/g,
    },
    {
        replacementLetter: "k",
        specialCharRegex: /[\u006b\u24da\uff4b\u0199\u2c6a\ua741\ua743\ua745\ua7a3]/g,
    },
    {
        replacementLetter: "l",
        specialCharRegex: /[\u006c\u24db\uff4c\u017f\u0142\u019a\u026b\u2c61\ua749\ua781\ua747]/g,
    },
    {
        replacementLetter: "m",
        specialCharRegex: /[\u006d\u24dc\uff4d\u0271\u026f]/g,
    },
    {
        replacementLetter: "n",
        specialCharRegex: /[\u006e\u24dd\uff4e\u019e\u0272\ua791\ua7a5]/g,
    },
    {
        replacementLetter: "o",
        specialCharRegex: /[\u006f\u24de\uff4f\u00f8\u0254\ua74b\ua74d\u0275]/g,
    },
    {
        replacementLetter: "oe",
        specialCharRegex: /\u0153/g,
    },
    {
        replacementLetter: "oi",
        specialCharRegex: /\u01a3/g,
    },
    {
        replacementLetter: "ou",
        specialCharRegex: /\u0223/g,
    },
    {
        replacementLetter: "oo",
        specialCharRegex: /\ua74f/g,
    },
    {
        replacementLetter: "p",
        specialCharRegex: /[\u0070\u24df\uff50\u01a5\u1d7d\ua751\ua753\ua755]/g,
    },
    {
        replacementLetter: "q",
        specialCharRegex: /[\u0071\u24e0\uff51\u024b\ua757\ua759]/g,
    },
    {
        replacementLetter: "r",
        specialCharRegex: /[\u0072\u24e1\uff52\u024d\u027d\ua75b\ua7a7\ua783]/g,
    },
    {
        replacementLetter: "s",
        specialCharRegex: /[\u0073\u24e2\uff53\u023f\ua7a9\ua785]/g,
    },
    {
        replacementLetter: "ss",
        specialCharRegex: /\u00df/g,
    },
    {
        replacementLetter: "t",
        specialCharRegex: /[\u0074\u24e3\uff54\u0167\u01ad\u0288\u2c66\ua787]/g,
    },
    {
        replacementLetter: "th",
        specialCharRegex: /\u00fe/g,
    },
    {
        replacementLetter: "tz",
        specialCharRegex: /\ua729/g,
    },
    {
        replacementLetter: "u",
        specialCharRegex: /[\u0075\u24e4\uff55\u0289]/g,
    },
    {
        replacementLetter: "v",
        specialCharRegex: /[\u0076\u24e5\uff56\u028b\ua75f\u028c]/g,
    },
    {
        replacementLetter: "vy",
        specialCharRegex: /\ua761/g,
    },
    {
        replacementLetter: "w",
        specialCharRegex: /[\u0077\u24e6\uff57\u2c73]/g,
    },
    {
        replacementLetter: "x",
        specialCharRegex: /[\u0078\u24e7\uff58]/g,
    },
    {
        replacementLetter: "y",
        specialCharRegex: /[\u0079\u24e8\uff59\u01b4\u024f\u1eff]/g,
    },
    {
        replacementLetter: "z",
        specialCharRegex: /[\u007a\u24e9\uff5a\u01b6\u0225\u0240\u2c6c\ua763]/g,
    },
];

/**
 * This function returns a normalized variant of the given string. It converts the entire string to lower case and then
 * removes all diacritics from unicode characters with unicode decomposition (NFD) and then also replaces remaining special characters
 * that can not be replaced with unicode normalization with regex.
 */
export function normalizeString(str: string) {
    const strWithoutDiacritics = str
        .toLowerCase()
        .normalize("NFD")
        .replace(/\p{Diacritic}/gu, "");

    // handle a special cases not handled by String.prototype.normalize decomposition
    let result = strWithoutDiacritics;
    lowerCaseNonDecomposableUnicodeChars.forEach((c) => {
        result = result.replace(c.specialCharRegex, c.replacementLetter);
    });

    return result;
}
