import { List, ListProps, Skeleton } from "@mui/material";
import { CommentDto, UserDto } from "api-shared";
import { TFunction } from "i18next";
import PropTypes from "prop-types";
import { useSubTaskHistory } from "../../domain/subtasks";
import useTimezone from "../../hooks/useTimezone";
import CommentList from "../comment/CommentList";
import MarkdownEditorUserMentionsMeasure from "../markdowneditor/MarkdownEditorUserMentionsMeasure";
import SubtaskHistoryItem from "./SubtaskHistoryItem";

function resolveUsers<Item extends { userId: number | null }>(item: Item, users: UserDto[]) {
    return Array.isArray(users) ? { ...item, user: users.find((u) => u.id === item.userId) } : item;
}

const ListSkeleton = (props: ListProps) => (
    <List {...props}>
        <Skeleton sx={{ m: 1 }} />
        <Skeleton sx={{ m: 1 }} />
        <Skeleton sx={{ m: 1 }} />
    </List>
);

interface ISubtaskProgressProps extends Omit<ListProps, "translate"> {
    comments?: CommentDto[];
    users: UserDto[];
    translate: TFunction;
    selectedTab: boolean;
    updateComment: (commentId: number, comment: string) => void;
    deleteComment: (id: number) => void;
    measureId: number;
    subTaskId: number;
    disabled?: boolean;
}

const SubtaskProgress = ({
    comments,
    users,
    translate,
    selectedTab,
    updateComment,
    deleteComment,
    measureId,
    subTaskId,
    disabled = false,
    ...listProps
}: ISubtaskProgressProps) => {
    const { formatDate, formatTime, formatDateTime } = useTimezone();
    const historyQuery = useSubTaskHistory(subTaskId);

    if (!selectedTab && comments != null) {
        const commentItems = comments
            .map((c) => ({ date: new Date(c.createdAt), userId: c.createdById, ...c }))
            .map((u) => resolveUsers(u, users))
            .sort((a, b) => a.date.valueOf() - b.date.valueOf());
        return (
            <CommentList
                dense
                comments={commentItems}
                updateComment={updateComment}
                deleteComment={deleteComment}
                parentId={measureId}
                customEditorComponent={MarkdownEditorUserMentionsMeasure}
                disabled={disabled}
            />
        );
    }

    if (!historyQuery.isSuccess) {
        return <ListSkeleton {...listProps} />;
    }
    const historyItems = historyQuery.data
        .map((h) => ({ ...h, date: new Date(h.datetime) }))
        .map((u) => resolveUsers(u, users))
        .sort((a, b) => b.date.valueOf() - a.date.valueOf());

    return (
        <List dense {...listProps}>
            {historyItems.map((item, index) => (
                <SubtaskHistoryItem
                    key={item.id}
                    hideDivider={index === historyItems.length - 1}
                    formatDate={formatDate}
                    formatTime={formatTime}
                    formatDateTime={formatDateTime}
                    item={item}
                    translate={translate}
                    users={users}
                />
            ))}
        </List>
    );
};
SubtaskProgress.propTypes = {
    comments: PropTypes.arrayOf(PropTypes.object).isRequired,
    users: PropTypes.arrayOf(PropTypes.object).isRequired,
    translate: PropTypes.func.isRequired,
    selectedTab: PropTypes.bool,
    updateComment: PropTypes.func,
    deleteComment: PropTypes.func,
};
export default SubtaskProgress;
