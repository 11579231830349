import { NotificationType } from "../lib/notifications";
import { AppState } from "./store";

export type NotificationCustomAction = {
    label: string;
    onClick: () => void;
};

export type Notification = {
    type: NotificationType;
    /**
     * Translation key that will be passed into translate function for displaying the notification
     *
     * @type {string}
     */
    message: string;
    /**
     * Additional parameters that are passed into the translate function for displaying the notification
     *
     * @type {(Record<string, string | number>)}
     */
    details: Record<string, string | number>;
    /**
     * Button to perform an action from within the notification toast
     */
    action?: NotificationCustomAction;
};

export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";

export function showNotificationEvent(
    type: NotificationType,
    message: string,
    details: Record<string, string | number> = {},
    action?: NotificationCustomAction,
) {
    return {
        type: SHOW_NOTIFICATION,
        notification: { type, message, action, details },
    };
}

const initialState = [] as Notification[];
export function notificationReducer(state = initialState, action: ReturnType<typeof showNotificationEvent>) {
    if (action.type === SHOW_NOTIFICATION) {
        return [...state, { ...action.notification }];
    }
    return state;
}

export function selectLastNotification(state: AppState) {
    return state.notifications.length > 0 ? state.notifications[state.notifications.length - 1] : undefined;
}
