import { ListItem, ListItemAvatar, ListItemButton, ListItemText, styled, SxProps } from "@mui/material";
import { formatUserName, UserBasicDto, UserDto } from "api-shared";
import { TFunction } from "i18next";
import React, { useState } from "react";
import CircularButton from "../CircularButton";
import UserAvatar, { IUserAvatarProps } from "./UserAvatar";
import UserEntryInline from "./UserEntryInline";
import UserName from "./UserName";

const FullWidthListItemText = styled(ListItemText)({
    width: "100%",
});

const DynamicWidthListItemAvatar = styled(ListItemAvatar, { shouldForwardProp: (name) => name !== "avatarSize" })<{ avatarSize?: number }>(
    ({ theme, avatarSize }) => ({
        display: "flex", // fix width not being correctly inferred
        // ensure always theme.spacing() px between avatar and text
        minWidth: avatarSize !== undefined ? `calc(${avatarSize}px + ${theme.spacing()})` : undefined,
    }),
);

export interface IUserEntryProps {
    user: UserDto | UserBasicDto | null | undefined;
    avatarProps?: Partial<IUserAvatarProps>;
    disabled?: boolean;
    iconOnly?: boolean;
    translate: TFunction;
    onClick?: React.MouseEventHandler<HTMLElement>;
    onAvatarClick?: React.MouseEventHandler<HTMLElement>;
    button?: boolean;
    disableGutters?: boolean;
    disablePadding?: boolean;
    action?: React.ReactNode;
    inline?: boolean;
    className?: string;
    style?: React.CSSProperties;
}

const UserEntry = React.forwardRef<any, IUserEntryProps>(
    (
        {
            disabled,
            user,
            iconOnly,
            action,
            inline,
            avatarProps,
            translate,
            onAvatarClick,
            onClick,
            className,
            button,
            disableGutters,
            disablePadding,
            style,
            // all other props need to be passed to the actual rendered component, so tooltip works correctly
            ...htmlProps
        },
        ref,
    ) => {
        const [isAvatarHover, setIsAvatarHover] = useState(false);

        const onAvatarOut = () => setIsAvatarHover(false);

        const onAvatarOver = () => setIsAvatarHover(true);

        // These props will be attached to the root component, which is different depending on what variant is used
        // e.g. iconOnly+disabled, iconOnly, button, listItem
        const rootProps = { ref, ...htmlProps };

        let avatar = (
            <UserAvatar
                // avoid attaching the ref when the avatar will not be the root element
                user={user}
                className={iconOnly && disabled ? className : undefined}
                // Attach ref & htmlProps when this element is the root element
                {...(iconOnly && disabled && onAvatarClick == null && rootProps)}
                {...avatarProps}
            />
        );

        if (!disabled && onAvatarClick != null && !inline) {
            // Wrap with a button so avatar gets interactive
            // avatar might get different interaction than its element, such as ListItemButton
            avatar = (
                <CircularButton
                    onClick={onAvatarClick}
                    onMouseOver={onAvatarOver}
                    onMouseOut={onAvatarOut}
                    // Attach ref & htmlProps when this element is the root element
                    {...(iconOnly && rootProps)}
                >
                    {avatar}
                </CircularButton>
            );
        }

        if (iconOnly) {
            return avatar;
        }

        const userName = <UserName key="username" user={user} withBadge translate={translate} />;
        const userNameString = user !== undefined ? formatUserName(user, { translate, withAdornment: false }) : undefined;

        if (inline) {
            return <UserEntryInline ref={ref} disabled={disabled} onClick={onClick} avatar={avatar} userName={userName} {...htmlProps} />;
        }

        // Need to do inline styles here, because the style values depend on the avatar size from props
        const children = (
            <>
                <DynamicWidthListItemAvatar avatarSize={avatarProps?.size}>{avatar}</DynamicWidthListItemAvatar>
                <FullWidthListItemText
                    key="text"
                    primary={userName}
                    primaryTypographyProps={{ noWrap: true, variant: "body2", display: "block" }}
                />
            </>
        );

        // Vertical padding will be shifted to button component if any, so hover looks a bit nicer
        const verticalPaddingStyles: SxProps = { py: disablePadding ? 0 : 0.5 };

        const isButton = onClick != null && !isAvatarHover;

        return (
            <ListItem
                secondaryAction={action}
                className={className}
                disableGutters={disableGutters}
                disablePadding={isButton || disablePadding}
                sx={!isButton ? { ...verticalPaddingStyles, minWidth: 0 } : { minWidth: 0 }}
                style={style}
                {...rootProps}
            >
                {isButton ? (
                    <ListItemButton
                        disableGutters={disableGutters}
                        aria-label={userNameString}
                        disabled={disabled}
                        sx={verticalPaddingStyles}
                        onClick={onClick}
                    >
                        {children}
                    </ListItemButton>
                ) : (
                    children
                )}
            </ListItem>
        );
    },
);

export default UserEntry;
