import { FormLabel, List, ListItem, ListItemText, styled } from "@mui/material";
import { useVirtualizer } from "@tanstack/react-virtual";
import { UserDto } from "api-shared";
import { TFunction } from "i18next";
import { useRef, useState } from "react";
import { formatUser } from "../../lib/formatters";
import { searchWithDiacritics } from "../../lib/search";
import { translationKeys } from "../../translations/main-translations";
import SearchInput from "../input/SearchInput";
import { getDynamicVirtualizedItemStyles, VIRTUAL_ITEM_BASE_STYLES } from "../virtual/helper";
import UserEntryWithPopup from "./UserEntryWithPopup";

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
    fontWeight: theme.typography.fontWeightBold,
}));

const StyledSearchInput = styled(SearchInput)(({ theme }) => ({
    marginTop: theme.spacing(2),
}));

const ScrollContainer = styled("div")(({ theme }) => ({
    overflowY: "auto",
    maxHeight: 290,
    marginTop: theme.spacing(1),
}));

const VirtualUserEntryWithPopup = styled(UserEntryWithPopup)(() => ({
    ...VIRTUAL_ITEM_BASE_STYLES,
}));

const UserList = styled(List)(() => ({
    position: "relative",
}));

interface IFilterableUserList {
    users: UserDto[];
    selectedUsers: number[];
    translate: TFunction;
    disabled?: boolean;
    disabledUsers?: number[];
    disabledUserMessage?: string;
    onSelect: (id: number) => void;
    labelChange: string;
    labelFill: string;
}

const FilterableUserList = ({
    users,
    selectedUsers,
    translate,
    disabled,
    disabledUsers,
    disabledUserMessage,
    onSelect,
    labelChange,
    labelFill,
}: IFilterableUserList) => {
    const [filter, setFilter] = useState("");

    const scrollElementRef = useRef<HTMLDivElement | null>(null);

    const availableUsers =
        Array.isArray(selectedUsers) && selectedUsers.length > 0
            ? users.filter((otherUser) => !selectedUsers.includes(otherUser.id))
            : users;

    const filteredUsers =
        filter.length > 0
            ? availableUsers.filter((otherUser) => searchWithDiacritics(formatUser(otherUser, { translate }), filter))
            : availableUsers;

    const rowVirtualizer = useVirtualizer({
        count: filteredUsers.length,
        getScrollElement: () => scrollElementRef.current,
        estimateSize: () => 48,
    });

    let emptyUserMessage;
    if (filteredUsers.length <= 0) {
        emptyUserMessage = translationKeys.VDLANG_USER_SELECT_WIDGET_NO_USER_RESULTS;
    }
    if (availableUsers.length <= 0 && selectedUsers.length <= 0) {
        emptyUserMessage = translationKeys.VDLANG_USER_SELECT_WIDGET_NO_SELECTABLE_USERS;
    }
    if (availableUsers.length <= 0 && selectedUsers.length > 0) {
        emptyUserMessage = translationKeys.VDLANG_USER_SELECT_WIDGET_NO_OTHER_SELECTABLE_USERS;
    }

    const totalSize = rowVirtualizer.getTotalSize();

    return (
        <>
            <StyledFormLabel>{selectedUsers.length > 0 ? labelChange : labelFill}</StyledFormLabel>
            <StyledSearchInput
                name="filter"
                disabled={disabled}
                placeholder={translate(translationKeys.VDLANG_USER_SELECT_WIDGET_SEARCH_PLACEHOLDER)}
                searchKey={filter}
                onChange={setFilter}
                translate={translate}
            />
            {emptyUserMessage !== undefined ? (
                <List>
                    <ListItem disableGutters>
                        <ListItemText secondary={translate(emptyUserMessage)} />
                    </ListItem>
                </List>
            ) : (
                <ScrollContainer ref={scrollElementRef}>
                    <UserList style={{ height: totalSize }}>
                        {rowVirtualizer.getVirtualItems().map((virtualItem) => {
                            const displayedUser = filteredUsers[virtualItem.index];

                            return (
                                <VirtualUserEntryWithPopup
                                    style={getDynamicVirtualizedItemStyles(virtualItem.size, virtualItem.start)}
                                    key={virtualItem.key}
                                    user={displayedUser}
                                    disabled={disabledUsers?.includes(displayedUser.id)}
                                    disableGutters
                                    onClick={typeof onSelect === "function" ? () => onSelect(displayedUser.id) : undefined}
                                    title={
                                        disabledUsers?.includes(displayedUser.id)
                                            ? disabledUserMessage
                                            : translate(translationKeys.VDLANG_USER_SELECT_WIDGET_SEARCH_ASSIGN)
                                    }
                                />
                            );
                        })}
                    </UserList>
                </ScrollContainer>
            )}
        </>
    );
};

export default FilterableUserList;
