import { Dialog, Grid, Grow, Paper, styled } from "@mui/material";
import { MeasureDto, SubTaskDto, SubTaskStatus, UserDto } from "api-shared";
import { keyBy } from "lodash";
import React, { Suspense, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import SubtaskDetail from "../../components/tasks/SubtaskDetail";
import { SubTaskUpdateChanges, useCreateSubtask, useDiscardSubtask, useUpdateSubtask } from "../../domain/subtasks";
import { useUiState } from "../../domain/ui-state";
import { IUseCachedOrderResult } from "../../hooks/useCachedOrder";
import { IUsePendingItemsResult } from "../../hooks/usePendingItems";
import { useIsDesktop } from "../../lib/mobile";
import SubtaskDetailSkeleton from "./SubtaskDetailSkeleton";
import SubtaskList from "./SubtaskList";
import SubtaskListGroupHeader from "./SubtaskListGroupHeader";
import { useEditableSubtaskTitle, useTaskIdFromUrl } from "./hooks";

const Container = styled(Grid)(({ theme }) => ({
    height: "100%",
    gap: theme.spacing(),
}));

const GridWithTransition = styled(Grid)(({ theme }) => ({
    transition: theme.transitions.create("all", { duration: theme.transitions.duration.enteringScreen }),
}));

const ListPaper = styled(Paper)({
    height: "100%",
    maxWidth: "100%",
    overflow: "hidden", // avoid scrollbar and content hiding the border-radius
});

const ListScrollContainer = styled("div")({
    overflow: "auto",
    height: "100%",
});

const newSubtask = {
    title: "",
    status: SubTaskStatus.STATUS_OPEN,
    comments: [],
};

const resolveVisibleGroups = (items: SubTaskDto[], groups?: number[][], order?: number[]): number[][] => {
    if (groups != null && groups.length > 0) {
        return groups;
    }
    if (order != null) {
        return [order];
    }
    return [items.map((i) => i.id)];
};

export interface ISubtaskMasterDetailProps extends Partial<IUsePendingItemsResult<SubTaskDto>>, Partial<IUseCachedOrderResult> {
    hideProgress?: boolean;
    tasks: SubTaskDto[];
    disabled?: boolean;
    groups?: number[][];
    order?: number[];
    users: UserDto[];
    hideMeasureInfo?: boolean;
    measure?: MeasureDto;
    isSortable?: boolean;
    isFetching?: boolean;
}

const SubtaskMasterDetail = ({
    hideProgress,
    tasks,
    disabled,
    groups,
    order,
    addPendingItem,
    removePendingItem,
    users,
    hideMeasureInfo,
    isSortable,
    isFetching,
    // from hocs
    pendingItems,
    measure,
}: ISubtaskMasterDetailProps) => {
    const [currentTaskId, setCurrentTaskId] = useTaskIdFromUrl();

    const updateSubTask = useUpdateSubtask().mutate;
    const createSubTask = useCreateSubtask().mutate;
    const discardSubTask = useDiscardSubtask();

    const [uiState] = useUiState(true);
    const { t: translate } = useTranslation();

    const isDesktop = useIsDesktop();

    const updateCurrentSubtask = useCallback(
        (changes: SubTaskUpdateChanges) => {
            if (disabled === true || currentTaskId === null || uiState == null) {
                return;
            }
            updateSubTask({ id: currentTaskId, ...changes });
        },
        [currentTaskId, disabled, uiState, updateSubTask],
    );

    const { modifiedTitle, setModifiedTitle, resetModifiedTitle, isValidTitle, updateSubtaskTitle } = useEditableSubtaskTitle({
        updateSubtask: updateCurrentSubtask,
    });

    const updateCurrentTaskId = useCallback(
        (id: number | null) => {
            setCurrentTaskId(id);
            resetModifiedTitle();
        },
        [resetModifiedTitle, setCurrentTaskId],
    );
    const openDetail = useCallback(({ id }: SubTaskDto) => updateCurrentTaskId(id), [updateCurrentTaskId]);
    const closeTaskDetail = useCallback(() => updateCurrentTaskId(null), [updateCurrentTaskId]);

    const addNewTask = useCallback(() => {
        if (addPendingItem) {
            closeTaskDetail();
            addPendingItem({ ...newSubtask });
        }
    }, [addPendingItem, closeTaskDetail]);

    const saveNewTask = useCallback(
        (title: string, shouldAddAnotherTask?: boolean) => {
            if (isValidTitle(title) && createSubTask !== undefined && measure) {
                createSubTask(
                    {
                        title,
                        measureId: measure.id,
                    },
                    { onSuccess: (response) => setCurrentTaskId(response.id) },
                );
                if (shouldAddAnotherTask) {
                    addNewTask();
                }
            } else {
                removePendingItem?.();
            }
        },
        [addNewTask, removePendingItem, createSubTask, isValidTitle, measure, setCurrentTaskId],
    );

    const getSubHeader = (group: SubTaskDto[], subTasksById: Record<number, SubTaskDto>, index: number) => {
        const sampleTask = group.map((st) => subTasksById[st.id]).find((u) => u != null);
        if (groups == null || groups.length === 0 || sampleTask === undefined || uiState == null) {
            return;
        }
        return (
            <SubtaskListGroupHeader
                sampleTask={sampleTask}
                groupBy={uiState.activityGroupBy}
                sort={uiState.activityGroupSort}
                index={index}
                translate={translate}
                users={users}
            />
        );
    };

    const subTasksById = useMemo(() => keyBy(tasks, "id"), [tasks]);
    const memoizedGroups = useMemo(() => {
        const visibleGroups = resolveVisibleGroups(tasks, groups, order);
        const allGroupsEmpty = visibleGroups.every((group) => group.length === 0);
        return visibleGroups.map((group, index) =>
            group.length > 0 || (index === 0 && allGroupsEmpty) ? group.map((i) => subTasksById[i]).filter((s) => s != null) : null,
        );
    }, [groups, order, subTasksById, tasks]);

    const showHint = !isFetching && !tasks.some((item) => item.id === currentTaskId);

    const subtaskDetail = (
        <SubtaskDetail
            key={currentTaskId}
            taskId={currentTaskId}
            translate={translate}
            onClose={closeTaskDetail}
            disabled={disabled ?? false}
            updateModifiedTitle={setModifiedTitle}
            onAbortTitle={resetModifiedTitle}
            onTitleSave={updateSubtaskTitle}
            updateTask={updateCurrentSubtask}
            users={users}
            onRemove={discardSubTask.mutate}
            modifiedTitle={modifiedTitle}
            hideProgress={hideProgress}
            hideMeasureInfo={hideMeasureInfo}
            showHint={showHint}
        />
    );

    return (
        <Container container wrap="nowrap">
            <GridWithTransition item xs={12} md={isDesktop && currentTaskId != null ? 6 : 12} container direction="column">
                <ListPaper>
                    <ListScrollContainer>
                        {isFetching ? (
                            <SubtaskList isCreatable={false} translate={translate} users={users} />
                        ) : (
                            memoizedGroups.map((group, index) =>
                                group !== null ? (
                                    <SubtaskList
                                        key={index}
                                        disabled={disabled}
                                        tasks={group}
                                        translate={translate}
                                        users={users}
                                        currentTaskId={currentTaskId}
                                        isCreatable={pendingItems != null && pendingItems.length <= 0}
                                        onOpen={openDetail}
                                        onTaskAdd={addNewTask}
                                        // editing title props
                                        title={modifiedTitle}
                                        onTitleSave={saveNewTask}
                                        onTitleAbort={removePendingItem}
                                        // list props
                                        subheader={getSubHeader(group, subTasksById, index)}
                                        showLevelChip
                                        isSortable={isSortable}
                                    />
                                ) : null,
                            )
                        )}
                    </ListScrollContainer>
                </ListPaper>
            </GridWithTransition>
            {currentTaskId != null && isDesktop ? (
                <Grow
                    in={true}
                    style={{
                        transformOrigin: "left center",
                    }}
                    mountOnEnter
                    unmountOnExit
                >
                    <Grid item xs={12} md={6} style={{ minHeight: "100%" }}>
                        <Suspense fallback={<SubtaskDetailSkeleton />}>{subtaskDetail}</Suspense>
                    </Grid>
                </Grow>
            ) : null}
            {currentTaskId != null && !isDesktop ? (
                <Dialog open={currentTaskId != null} fullScreen>
                    {subtaskDetail}
                </Dialog>
            ) : null}
        </Container>
    );
};
export default React.memo(SubtaskMasterDetail);
