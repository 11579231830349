// Exported (and camelized) from zeplin via JSS plugin
export const STYLEGUIDE_COLORS = {
    primaryBlue: "#0277bd",
    valueBlue: "#0b3c5d",
    magicPurple: "#7e57c2",
    ocean: "#009688",
    secondaryOrange: "#ff9600",
    materialRed: "#e64a19",
    natureGreen: "#66bb6a",
    successGreen: "#00c853",
    defaultGreen: "rgba(76, 175, 80, 1)",
    fontDark: "rgba(0, 0, 0, 0.87)",
    fontDarkblue: "#263238",
    fontGrey: "rgba(0, 0, 0, 0.6)",
    fontLightGrey: "rgba(0, 0, 0, 0.54)",
    fontInactive: "rgba(0, 0, 0, 0.38)",
    backgroundLight: "#F2F4F6",
    border: "rgba(0, 0, 0, 0.24)",
    dashboardRed: "#E76F51",
    dashboardOrange: "#F4A261",
    dashboardYellow: "#E9C46A",
    dashboardPurple: "#5C6BC0",
    dashboardGreen: "#2A9D8F",
    dashboardDarkBlue: "#264653",
    dashboardLightBlue: "#5AAAD8",
    dashboardLightPurple: "#955EB7",
    dashboardViolet: "#DF76C8",
    dashboardAqua: "#70D6CF",
    dashboardSeaGreen: "#80C97E",
    dashboardIndianRed: "#C37F73",
    defaultDark: "#CFD4D7",
};

// names of colors used for special purposes, right now: primary and secondary
export type SpecialColors = keyof typeof MapColorsToMUIThemeColors;

// values are purely for documentation and to filter out which STYLEGUIDE_COLORS are used as special colors
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MapColorsToMUIThemeColors = {
    primaryBlue: "primary",
    secondaryOrange: "secondary",
    fontDark: "text.primary",
    fontGrey: "font.secondary",
    fontInactive: "font.disabled",
    successGreen: "success.main",
    materialRed: "error.main",
    backgroundLight: "background.default",
};
