// The promise here does not need to return anything
/* eslint-disable promise/always-return */
/** @jsxRuntime classic */
// IE11 needs "jsxRuntime classic" for this initial file which means that "React" needs to be in scope
// https://github.com/facebook/create-react-app/issues/9906
import * as Sentry from "@sentry/react";
import { scrubData } from "api-shared";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import "./infrastructure/polyfills";
import { createQueryClient, registerQueryClientAction } from "./infrastructure/react-query";
import { configureApplicationStore } from "./infrastructure/store";
import { bootstrapEvent } from "./lib/bootstrap";
import { registerDisposeListener } from "./lib/dispose-saga";
import { environment, fetchConfig } from "./lib/environment";
// setup re-render tracking in development mode
// import "./lib/why-did-you-render";

// Static reference so that React import is not removed by vscode
// eslint-disable-next-line @typescript-eslint/no-unused-expressions
React;

fetchConfig()
    .then((config) => {
        try {
            // check if local storage is available
            const x = "__local_storage_check__";
            localStorage.setItem(x, x);
            localStorage.removeItem(x);
        } catch {
            // local storage is not available => show info text
            const container = document.getElementById("root");

            if (container !== null) {
                container.innerHTML =
                    "Your browser is blocking cookies and web site data, without which Valuedesk will not work. Please check your browser settings.";
            }

            return;
        }

        environment.initialize(config);

        if (environment.sentryClientDsn && environment.sentryEnvironment) {
            Sentry.init({
                dsn: environment.sentryClientDsn,
                environment: environment.sentryEnvironment,
                release: environment.version,
                integrations: [
                    Sentry.browserTracingIntegration(),
                    Sentry.extraErrorDataIntegration({ depth: 4 }),
                    // Disable Sentry's global handlers, because our custom ErrorBoundary will handle them already
                    Sentry.globalHandlersIntegration({ onerror: false, onunhandledrejection: false }),
                    Sentry.replayIntegration(),
                    Sentry.captureConsoleIntegration(),
                ],
                // Set the local sampleRate to 1 if needed
                tracesSampleRate: environment.sentryEnvironment === "local" ? 0 : 0.2,
                replaysSessionSampleRate: environment.sentryEnvironment === "local" ? 0 : 0.01,
                replaysOnErrorSampleRate: 1.0,
                beforeSend: scrubData,
            });
        }
        const store = configureApplicationStore();
        const queryClient = createQueryClient(store);

        // announce the created query client to the provided store & sagas
        store.dispatch(registerQueryClientAction(queryClient));

        registerDisposeListener(store);

        store.dispatch(bootstrapEvent(queryClient));

        const container = document.getElementById("root");

        if (container === null) {
            return;
        }

        const root = createRoot(container);
        root.render(<App queryClient={queryClient} store={store} />);
    })
    .catch((e: unknown) => {
        const message = e instanceof Error ? e.message : JSON.stringify(e);
        const container = document.getElementById("root");

        if (container === null) {
            return;
        }

        const root = createRoot(container);
        root.render(<p>{`Error: ${message}`}</p>);
    });
