import { CSSProperties } from "react";

/**
 * This are basic css styles all items in a virtual lists need, see: https://tanstack.com/virtual/latest/docs/introduction#the-virtualizer
 *
 * @example
 * These styles can be safely used in style component definitions.
 *
 * ```ts
 * const VirtualItem = styled("div")(() => ({ ...VIRTUAL_ITEM_BASE_STYLES }));
 * ```
 */
export const VIRTUAL_ITEM_BASE_STYLES = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
} as CSSProperties;

/**
 * Returns dynamic height and transform properties for items in a virtualized list. Be careful when using these in styled components to not generate
 * a new class for each item. Recommendation is to use these styles only with the react-dom native `styles` prop.
 */
export function getDynamicVirtualizedItemStyles(height: number, start: number): CSSProperties {
    return {
        height,
        transform: `translateY(${start}px)`,
    };
}
